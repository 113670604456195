import { Inject, Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ProcedureMap } from '@shared/models/procedure-map';
import { LoggerService } from '@core/services/logger/logger.service';
import { SendToTypeEnum, SendToIdForNotLabsEnum } from '../models/send-to-type.enum';
import { IdName } from '@shared/models/id-name';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay, filter } from 'rxjs/operators';
import { OrderQuery } from '@modules/order/state/order-query';
import { RX_GENERAL_RULES } from '@shared/rules/rules-tokens';
import { RxGeneralRules } from '@shared/models/rx-general-rules';
import { TypeEnum } from '@modules/order/models/type.enum';
import { combineQueries } from '@datorama/akita';
import { Procedure } from '@shared/models/procedure';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';

interface ProcedureTypeSendToIds {
	procedureId: number;
	typeId?: number;
	sendToId?: number;
}

@Injectable({
	providedIn: 'root'
})
export class ProcedureConfigurationService {
	private sendToTypeMapper: Record<SendToIdForNotLabsEnum, SendToTypeEnum> = {
		[SendToIdForNotLabsEnum.glidewell]: SendToTypeEnum.glidewell,
		[SendToIdForNotLabsEnum.chairside]: SendToTypeEnum.chairside
	};

	procedures$: Observable<Procedure[]> = combineQueries([
		this.orderQuery.availableProcedureMaps$,
		this.shellQuery.procedures$,
		this.shellQuery.isCloningMode$,
		this.shellQuery.enableAllCaseTypesForAddRx$
	]).pipe(
		map(args => this.getAvailableProcedures(...args)),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	types$: Observable<IdName[]> = combineQueries([this.orderQuery.availableProcedureMaps$, this.orderQuery.procedureMap$]).pipe(
		filter(([, procedureMap]) => !!procedureMap),
		map(([availableProcedureMaps, procedureMap]) => this.getTypes(availableProcedureMaps, procedureMap.ProcedureId)),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	constructor(
		private shellQuery: ShellQuery,
		private orderQuery: OrderQuery,
		private logger: LoggerService,
		private translateService: TranslateService,
		@Inject(RX_GENERAL_RULES) private rxGeneralRules: RxGeneralRules
	) {}

	getAvailableProcedures(
		procedureMaps: ProcedureMap[],
		procedures: Procedure[],
		isCloningMode: boolean,
		enableAllCaseTypesForAddRx: boolean
	): Procedure[] {
		if (!procedureMaps?.length || !procedures.length) {
			return [];
		}

		const sortedProcedures = this.getSortedProcedures(procedures);

		if (!!isCloningMode && !enableAllCaseTypesForAddRx) {
			return sortedProcedures.filter(
				({ Id, AddRxAvailable }) => !!AddRxAvailable && procedureMaps.some(({ ProcedureId }) => Id === ProcedureId)
			);
		}

		return sortedProcedures.filter(({ Id }) => procedureMaps.some(({ ProcedureId }) => Id === ProcedureId));
	}

	getProcedureMap(procedureId: number, typeId?: number, sendToId?: number): ProcedureMap {
		let procedureMap: ProcedureMap;

		if (typeId === null || typeId === undefined) {
			// use default or empty typeId
			typeId = this.rxGeneralRules.DefaultTypes.find(x => x.ProcedureId === procedureId)?.TypeId ?? TypeEnum.Empty;
			procedureMap = this.orderQuery.availableProcedureMaps.find(x => this.checkProcedureMap(x, { procedureId, typeId, sendToId }));

			if (procedureMap) {
				return procedureMap;
			}
			typeId = undefined; // allow first available typeId
		}

		procedureMap = this.orderQuery.availableProcedureMaps.find(pm => this.checkProcedureMap(pm, { procedureId, typeId, sendToId }));
		if (!procedureMap) {
			this.logger.error(`getProcedureMapItem: no procedure map for: ${JSON.stringify({ procedureId, typeId, sendToId })}`, {
				module: 'ProcedureConfigurationService'
			});
		}
		return procedureMap;
	}

	getAvailableLabs(params: { procedureId: number; typeId?: number }): IdName[] {
		const { procedureId, typeId } = params;
		let availableLabs = [];

		const sendToTypes = this.orderQuery.availableProcedureMaps
			.filter(x => this.checkProcedureMapByProcedureAndType(x, { procedureId, typeId }))
			.map(x => x.SendToTypes);

		if (sendToTypes.includes(SendToTypeEnum.orthoLab)) {
			availableLabs.push(...this.shellQuery.orthoLabs);
		}

		if (sendToTypes.includes(SendToTypeEnum.restoLab)) {
			availableLabs.push(...this.shellQuery.dentalLabs);
		}

		if (sendToTypes.includes(SendToTypeEnum.glidewell)) {
			availableLabs.push(this.getSendToIdForNotLabs(SendToTypeEnum.glidewell));
		}

		if (sendToTypes.includes(SendToTypeEnum.chairside)) {
			availableLabs.push(this.getSendToIdForNotLabs(SendToTypeEnum.chairside));
		}

		availableLabs = this.getUniqueLabs(availableLabs);
		return availableLabs.length > 0 ? availableLabs : null;
	}

	getSendToIdForNotLabs(sendToType: SendToTypeEnum): IdName {
		const id = parseInt(
			Object.keys(this.sendToTypeMapper).find(typeMapperKey => this.sendToTypeMapper[typeMapperKey] === sendToType),
			10
		);
		if (!id) {
			return null;
		}
		const nameSource = sendToType === SendToTypeEnum.chairside ? 'OrderSection.ChairSide' : 'OrderSection.Glideweill';

		return { Id: id, Name: this.translateService.instant(nameSource) };
	}

	getTypes(availableProcedureMaps: ProcedureMap[], procedureId: number): IdName[] {
		if (!availableProcedureMaps || !this.shellQuery.rxConfiguration?.Types) {
			this.logger.error(
				`Available procedure maps is missing or rx configuration is missing types ${JSON.stringify(
					this.shellQuery.rxConfiguration
				)}`,
				{
					module: 'procedure-configuration.service'
				}
			);
			return [];
		}
		const typeIds = availableProcedureMaps?.filter(x => x.ProcedureId === procedureId).map(x => x.TypeId);

		if (typeIds.length === 0) {
			return [];
		}

		return this.shellQuery.rxConfiguration?.Types?.filter(x => typeIds.includes(x.Id));
	}

	private checkProcedureMap(procedureMap: ProcedureMap, checkedValues: ProcedureTypeSendToIds): boolean {
		return (
			this.checkProcedureMapByProcedureAndType(procedureMap, checkedValues) &&
			((!checkedValues.sendToId && procedureMap.SendToTypes === SendToTypeEnum.empty) ||
				this.getSendToTypes(checkedValues.sendToId).includes(procedureMap.SendToTypes))
		);
	}

	private checkProcedureMapByProcedureAndType(procedureMap: ProcedureMap, { procedureId, typeId }: ProcedureTypeSendToIds) {
		return procedureMap.ProcedureId === procedureId && (typeId === undefined || typeId === null || procedureMap.TypeId === typeId);
	}

	private getSendToTypes(sendToId: number): SendToTypeEnum[] {
		const result = [];
		if (this.shellQuery.orthoLabs.some(x => x.Id === sendToId)) {
			result.push(SendToTypeEnum.orthoLab);
		}

		if (this.shellQuery.dentalLabs.some(x => x.Id === sendToId)) {
			result.push(SendToTypeEnum.restoLab);
		}

		return result.length > 0 ? result : [this.sendToTypeMapper[sendToId]];
	}

	private getUniqueLabs(labs: IdName[]): IdName[] {
		const uniqueLabs = new Map<number, IdName>(labs.map(v => [v.Id, v]));
		return Array.from(uniqueLabs.values());
	}

	private getSortedProcedures(procedures: Procedure[]): Procedure[] {
		const sortingOrder = new Map<number, number>([
			[ProcedureEnum.StudyModel_iRecord, 1],
			[ProcedureEnum.Invisalign, 2],
			[ProcedureEnum.FixedRestorative, 3],
			[ProcedureEnum.ImplantPlanning, 4],
			[ProcedureEnum.Denture_Removable, 5],
			[ProcedureEnum.Appliance, 6]
		]);
		const procedureList = [...procedures];

		return procedureList.sort((p1, p2) => sortingOrder.get(p1.Id) - sortingOrder.get(p2.Id));
	}
}
