export enum SendToTypeEnum {
	empty = 0,
	orthoLab = 1,
	restoLab = 2,
	glidewell = 3,
	// TODO rename to exocad because Glidewell and Exocad have "chairside" technique
	chairside = 4
}

export enum SendToIdForNotLabsEnum {
	glidewell = -999,
	chairside = -1000,
}