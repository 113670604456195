import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToothEditorQuery } from './state/tooth-editor-query';
import { AbutmentData, CrownData, ToothEditorData, ToothEditorState } from './models/tooth-editor';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';
import { getNewTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { IdName } from '@shared/models/id-name';
import { OrderQuery } from '@modules/order/state/order-query';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { RxRulesService } from '@shared/services/rx-rules-helper/rx-rules.service';
import { Material } from '@shared/models/material';
import { ToothEditorStore } from './state/tooth-editor-store';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ImplantMasterList } from '@shared/models/implant-master-list';
import { BridgeService } from '@shared/services/bridge.service';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { IBaseFacade } from '@shared/base-classes/base.facade';
import { AbutmentType } from '@shared/models/abutment-type';
import { VersionsService } from '@shared/services/versions.service';
import { LimitedFeatures } from '@shared/models/limited-features';
import { getSortedListByName } from '@shared/services/sort-list-by-name-util';
import { AbutmentTypes } from '@shared/models/abutment-type.enum';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { getToothNumberById } from '@modules/teeth-diagram/models/teeth-numbering';
import { ProcedureMap } from '@shared/models/procedure-map';
import { MaterialOption } from '@shared/models/material-option';
import { ShadeSystem } from '@shared/models/shade-system';
import { FormGroup } from '@angular/forms';
import { TeethDiagramStore } from '@modules/teeth-diagram/state/teeth-diagram-store';
import { ToothEditorUpdatingService } from '@modules/tooth-editor/services/tooth-editor-updating.service';
import { SpecIdWithMaterialIds } from '@shared/models/rx-rules-json-interface';
import { Specification } from '@shared/models/specification';
import { BridgeValidationState, BridgeValidatorService } from '@shared/services/bridge-validator.service';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable()
export class ToothEditorFacade implements IBaseFacade {
	constructor(
		private translateService: TranslateService,
		private toothEditorQuery: ToothEditorQuery,
		private toothEditorStore: ToothEditorStore,
		private teethDiagramStore: TeethDiagramStore,
		private teethDiagramQuery: TeethDiagramQuery,
		private shellQuery: ShellQuery,
		private orderQuery: OrderQuery,
		private rxRulesService: RxRulesService,
		private bridgeService: BridgeService,
		private versionService: VersionsService,
		private updatingService: ToothEditorUpdatingService,
		private bridgeValidatorService: BridgeValidatorService,
		private hostPlatformService: HostPlatformService
	) {}
	getSortedListByName = getSortedListByName;

	isRxSent$: Observable<boolean> = this.shellQuery.isRxSent$;
	getState$: Observable<ToothEditorState> = this.toothEditorQuery.toothEditorState$;
	teeth$: Observable<Tooth[]> = this.toothEditorQuery.teeth$;
	teethNumberingSystem$: Observable<TeethNumberingSystem> = this.teethDiagramQuery.teethNumberingSystem$;
	isBridge$: Observable<boolean> = this.toothEditorQuery.isBridge$;
	allTeethInJaw$: Observable<Tooth[]> = this.toothEditorQuery.allTeethInJaw$;
	toothClickedOn$: Observable<Tooth> = this.toothEditorQuery.toothClickedOn$;
	implantMasterList$: Observable<ImplantMasterList> = this.shellQuery.implantMasterList$;
	isReadOnly$: Observable<boolean> = combineLatest([
		this.shellQuery.getReadOnlyState('toothEditor'),
		this.hostPlatformService.isIteroLab$
	]).pipe(map(([isReadOnly, isHostPlatformIteroLab]) => isReadOnly || isHostPlatformIteroLab));

	abutmentTypes: AbutmentType[];
	abutmentTypes$: Observable<AbutmentType[]> = this.rxRulesService.abutmentTypes$.pipe(
		tap(abutmentTypes => (this.abutmentTypes = abutmentTypes))
	);
	clientVersion$: Observable<string> = this.shellQuery.clientVersion$;
	highestPackageVersion$: Observable<string> = this.shellQuery.highestPackageVersion$;

	crownSectionMaterialOptions$: Observable<IdName[]> = combineLatest([
		this.shellQuery.rxConfiguration$,
		this.orderQuery.caseType$,
		this.toothClickedOn$
	]).pipe(
		map(([rxConfig, caseTypeItem, toothClickedOn]: [RxConfiguration, IdName, Tooth]) => {
			const materialNames: Material[] = rxConfig ? [...rxConfig.Materials] : [];
			const materialIds = this.rxRulesService.getMaterialIds(caseTypeItem?.Id, toothClickedOn.ToothID, toothClickedOn.UnitTypeID);
			return this.rxRulesService.getFilteredMaterials(materialIds, materialNames);
		})
	);

	materialsByDefault$: Observable<Material[]> = combineLatest([
		this.toothClickedOn$,
		this.shellQuery.rxConfiguration$,
		this.orderQuery.caseType$,
		this.shellQuery.isNewRx$
	]).pipe(
		map(([toothClickedOn, rxConfig, caseTypeItem, isNewRx]: [Tooth, RxConfiguration, IdName, boolean]) => {
			const isBridgeTooth = !toothClickedOn?.UnitTypeID;
			const unitTypeConverted = isBridgeTooth
				? this.convertFromBridgeToothUnitType({ unitTypeInBridge: toothClickedOn?.ToothInBridgeTypeID })
				: toothClickedOn?.UnitTypeID;
			const materialNames: Material[] = rxConfig
				? [...rxConfig.Materials].filter((material: Material) => (isNewRx ? material.IsActive : true))
				: [];
			const materialIds = this.rxRulesService.getMaterialIds(caseTypeItem?.Id, toothClickedOn?.ToothID, unitTypeConverted);
			return this.rxRulesService.getFilteredMaterials(materialIds, materialNames);
		})
	);

	allMaterialNames: IdName[];
	allMaterialNames$: Observable<Material[]> = combineLatest([this.shellQuery.rxConfiguration$, this.shellQuery.isNewRx$]).pipe(
		map(([rxConfig, isNewRx]: [RxConfiguration, boolean]) => {
			const materialNames: Material[] = rxConfig
				? [...rxConfig.Materials].filter((material: Material) => (isNewRx ? material.IsActive : true))
				: [];
			const materialNameOptions = materialNames?.map(materialName => ({ Id: materialName.Id, Name: materialName.Name }));
			this.allMaterialNames = this.getSortedListByName(materialNameOptions);
			return materialNames;
		})
	);

	allAbutmentTypeOptions: IdName[];
	allAbutmentTypeOptions$: Observable<IdName[]> = this.abutmentTypes$
		.pipe(map(abutmentTypes => abutmentTypes.map(abutmentType => ({ Id: abutmentType.Id, Name: abutmentType.Name }))))
		.pipe(tap(allAbutmentTypeOptions => (this.allAbutmentTypeOptions = allAbutmentTypeOptions)));

	abutmentTypeOptions: IdName[];
	/* @TODO: will use HighestPackageVersions$ instead of HighestPackageVersion$ when ScrewRetainCrown requirements are elaborated */
	abutmentTypeOptions$: Observable<IdName[]> = combineLatest([
		this.abutmentTypes$,
		this.clientVersion$,
		this.shellQuery.packageVersion$,
		this.highestPackageVersion$,
		this.hostPlatformService.isScanner$
	])
		.pipe(
			map(
				([abutmentTypes, clientVersion, packageVersion, highestPackageVersion, isHostPlatformScanner]: [
					AbutmentType[],
					string,
					string,
					string,
					boolean
				]) =>
					abutmentTypes
						.filter((abutmentType: AbutmentType) => {
							packageVersion = isHostPlatformScanner ? packageVersion : highestPackageVersion;
							return (
								abutmentType.Id !== AbutmentTypes.ScrewRetainedCrown ||
								(abutmentType.Id === AbutmentTypes.ScrewRetainedCrown &&
									this.versionService.isFeatureAvailableForScanner(
										LimitedFeatures.ScrewRetainCrown,
										clientVersion,
										packageVersion
									))
							);
						})
						?.map((abutmentType: AbutmentType): IdName => ({ Id: abutmentType.Id, Name: abutmentType.Name }))
			)
		)
		.pipe(tap(abutmentTypeOptions => (this.abutmentTypeOptions = this.getSortedListByName(abutmentTypeOptions))));

	copyFromToothOptions$: Observable<IdName[]> = combineLatest([
		this.teethDiagramQuery.teeth$,
		this.teethDiagramQuery.teethNumberingSystem$
	]).pipe(
		map(([teeth, teethNumberingSystem]) => {
			const jaws = [...teeth.upperJaw, ...teeth.lowerJaw];
			const allPreps = jaws.filter(tooth => this.isPrep({ tooth }) && this.toothClickedOn.ToothID !== tooth.ToothID);
			return allPreps.map(prepTooth => ({
				Id: prepTooth.ToothID,
				Name: `${getToothNumberById({ toothId: prepTooth.ToothID, teethNumberingSystem })} (${this.getUnitTypeTranslation({
					tooth: prepTooth,
					isBridgeContext: !!this.toothClickedOn.BridgeIndex
				})})`
			}));
		})
	);

	shouldValidateForSend$: Observable<boolean> = this.shellQuery.shouldValidateForSend$;

	get isProcedureFlow(): boolean {
		return this.shellQuery.isProcedureFlow;
	}

	get procedureMap(): ProcedureMap {
		return this.orderQuery.procedureMap;
	}

	get toothClickedOn(): Tooth {
		return this.toothEditorQuery.toothClickedOn;
	}

	get teeth(): Tooth[] {
		return this.toothEditorQuery.teeth;
	}

	get isToothInBridge(): boolean {
		return !!this.toothClickedOn?.BridgeIndex;
	}

	get isReadOnlyBannerVisible(): Observable<boolean> {
		return combineLatest([this.isReadOnly$, this.isRxSent$, this.hostPlatformService.isScanner$]).pipe(
			map(([isReadOnly, isRxSent, isHostPlatformScanner]) => {
				return !isHostPlatformScanner && isReadOnly && isRxSent;
			})
		);
	}

	get ponticDesigns(): ConfigurationItem[] {
		return this.shellQuery.ponticDesigns;
	}

	get isGlidewellOrder(): boolean {
		return this.orderQuery.isGlidewellOrder;
	}

	getMaterialsBySpecification(specId: number, specIdsWithMaterialIds: SpecIdWithMaterialIds[]): Material[] {
		const ids = this.rxRulesService.getMaterialIdsBySpecification(specId, specIdsWithMaterialIds);

		return this.rxRulesService.getFilteredMaterials(ids);
	}

	getFilteredSpecifications(specIds: number[], specifications?: Specification[]): Specification[] {
		return this.rxRulesService.getFilteredSpecifications(specIds, specifications);
	}

	getSpecIdsWithMaterialIds(procedureMapId: number, toothId: number, currentUnitTypeOnTooth: UnitTypes): SpecIdWithMaterialIds[] {
		return this.rxRulesService.getSpecIdsWithMaterialIds(procedureMapId, toothId, currentUnitTypeOnTooth);
	}

	getSpecIdsWithMaterialIdsForBridge(
		procedureMapId: number,
		toothId: number,
		bridgeUnitTypeOnTooth: UnitTypesInBridge
	): SpecIdWithMaterialIds[] {
		return this.rxRulesService.getSpecIdsWithMaterialIdsForBridge(procedureMapId, toothId, bridgeUnitTypeOnTooth);
	}

	convertToBridgeToothUnitType({ unitType }: { unitType: UnitTypes }): UnitTypesInBridge {
		return this.bridgeService.convertToBridgeToothUnitType({ unitType });
	}

	convertFromBridgeToothUnitType({ unitTypeInBridge }: { unitTypeInBridge: UnitTypesInBridge }): UnitTypes {
		return this.bridgeService.convertFromBridgeToothUnitType({ unitTypeInBridge });
	}
	getPreparationDesignOptionsMapping(): IdName[] {
		return this.rxRulesService.getPreparationDesignOptionsMapping();
	}

	getStumpShadeOptions(): IdName[] {
		return this.rxRulesService.getStumpShadeOptions();
	}

	getMarginDesignOptions(): IdName[] {
		return this.rxRulesService.getMarginDesignOptions();
	}

	getMaterialOption(materialId: number): MaterialOption {
		return this.isProcedureFlow ? this.getMaterialSpecOption(materialId) : this.getMaterialUnitTypeOption(materialId);
	}

	getMaterialSpecOption(materialId: number): MaterialOption {
		return this.rxRulesService.getMaterialSpecOption(materialId, this.toothClickedOn?.SpecificationId);
	}

	getMaterialUnitTypeOption(materialId: number): MaterialOption {
		const isBridgeTooth = !this.toothClickedOn?.UnitTypeID;
		const convertedFromBridgeUnitType = this.convertFromBridgeToothUnitType({
			unitTypeInBridge: this.toothClickedOn?.ToothInBridgeTypeID
		});
		const unitTypeId = isBridgeTooth ? convertedFromBridgeUnitType : this.toothClickedOn?.UnitTypeID;

		return this.rxRulesService.getMaterialUnitTypeOption({ materialId, unitTypeId });
	}

	setFormGroupReadOnlyMode(formGroup: FormGroup): void {
		Object.values(formGroup.controls).map(control => {
			control.disable();
		});
	}

	updateToothClickedOn({ toothClickedOn }: { toothClickedOn: Tooth }) {
		this.toothEditorStore.updateToothClickedOn({ toothClickedOn });
	}

	updateIsBridge({ isBridge }: { isBridge: boolean }) {
		this.toothEditorStore.updateIsBridge({ isBridge });
	}

	updateAllTeethInJaw({ allTeethInJaw }: { allTeethInJaw: Tooth[] }) {
		this.toothEditorStore.updateAllTeethInJaw({ allTeethInJaw });
	}

	getAvailableBridgeSpan({ toothClickedOn, allTeethInJaw }: { toothClickedOn: Tooth; allTeethInJaw: Tooth[] }) {
		return this.bridgeService.getAvailableBridgeSpan({ toothClickedOn, allTeethInJaw });
	}

	getAbutmentMarginStyleName({ marginDesignId }: { marginDesignId: string }): string {
		const abutmentMarginDesigns: IdName[] = this.getAbutmentOptions();
		const abutmentMarginStyleName = abutmentMarginDesigns?.find(
			abutmentMarginDesignOption => abutmentMarginDesignOption.Id === marginDesignId
		)?.Name;
		return abutmentMarginStyleName ? this.translateService.instant(`MarginStyles.${abutmentMarginStyleName}`) : undefined;
	}

	initTeeth({ teeth }: { teeth: Tooth[] }): void {
		this.toothEditorStore.updateTeeth({ teeth });
	}

	selectNearestToothInBridge(teeth: Tooth[]): void {
		const firstToSelected = teeth[0].ToothID - this.toothClickedOn.ToothID;
		const selectedToLast = this.toothClickedOn.ToothID - teeth[teeth.length - 1].ToothID;

		if (firstToSelected * selectedToLast >= 0) {
			return;
		}

		if (Math.abs(firstToSelected) < Math.abs(selectedToLast)) {
			this.updateToothClickedOn({ toothClickedOn: teeth[0] });
		} else {
			this.updateToothClickedOn({ toothClickedOn: teeth[teeth.length - 1] });
		}
	}

	updateTooth({ tooth, crownData, abutmentData, implantTypeId }: ToothEditorData): void {
		const updatedTooth = this.getUpdatedTooth({ tooth, crownData, abutmentData, implantTypeId });

		this.updatingService.updateSelectedTooth(updatedTooth);
	}

	updateToothAndSharedFieldsInBridge({ tooth, crownData, abutmentData, implantTypeId }: ToothEditorData): void {
		const updatedTooth = this.getUpdatedTooth({ tooth, crownData, abutmentData, implantTypeId });

		this.updatingService.updateSelectedToothAndSharedFieldsInBridge(updatedTooth);
	}

	getStateSync(property?: string) {
		return property ? this.toothEditorQuery.getValue()[property] : this.toothEditorQuery.getValue();
	}

	getUnitTypeNames(tooth: Tooth): Observable<IdName[]> {
		if (this.isProcedureFlow) {
			return this.getProcedureFlowUnitTypeNames(tooth);
		}

		return this.getCaseTypeFlowUnitTypeNames(tooth);
	}

	getUnitTypeNamesForBridge(tooth: Tooth): Observable<IdName[]> {
		if (this.isProcedureFlow) {
			return this.getProcedureFlowUnitTypeNamesForBridge(tooth);
		}

		return this.getCaseTypeFlowUnitTypeNamesForBridge(tooth);
	}

	getCaseTypeFlowUnitTypeNamesForBridge(tooth: Tooth): Observable<IdName[]> {
		return this.teeth$.pipe(
			take(1),
			map((teethInBridge: Tooth[]) => {
				const isOuterTooth = this.bridgeService.isOuterTooth(tooth, teethInBridge);
				if (this.isGlidewellOrder) {
					return this.rxRulesService.getCaseTypeFlowUnitTypeNamesForGlidewellBridge(isOuterTooth);
				}

				return this.rxRulesService.getCaseTypeFlowUnitTypeNamesForBridge(isOuterTooth);
			})
		);
	}

	mapPreparationDesignOptions(prepDesignValues: string[]): IdName[] {
		return prepDesignValues?.map((prepDesignId: string) => this.getPreparationDesignOption(prepDesignId));
	}

	getPreparationDesignOption(preparationDesignId: string): IdName {
		return {
			Id: preparationDesignId,
			Name: this.getPreparationDesignName({ preparationDesignId })
		};
	}

	getDefaultShadeSystemFromUserSettings = ({ defaultShadeSystemOptions }: { defaultShadeSystemOptions: ShadeSystem[] }): ShadeSystem => {
		const ShadeSystemId = this.shellQuery.userSettings?.ShadeSystemId;
		return defaultShadeSystemOptions?.find(option => option.Id === ShadeSystemId);
	};

	getShadeSystemOptionsByMaterialId = ({ materialId }: { materialId: number }) => {
		return this.rxRulesService.getShadeSystemOptionsByMaterialId({ materialId });
	};

	getDefaultShadeSystemOptions(): ShadeSystem[] {
		return this.rxRulesService.getDefaultShadeSystemOptions();
	}

	getDefaultShadeSystemSelection = ({ materialId }: { materialId: number }) => {
		return this.rxRulesService.getDefaultShadeSystemSelectionId({ materialId });
	};

	getIncisalBodyGingivalOptions = ({ materialId, shadeSystemId }: { materialId: number; shadeSystemId: number }): IdName[] => {
		return this.rxRulesService.getIncisalBodyGingivalOptions({ materialId, shadeSystemId });
	};

	getAbutmentOptions(): IdName[] {
		return this.rxRulesService.getAbutmentOptionsMapping();
	}

	getBridgeTeethToUpdate({ teethToUpdate, teethInJaw }: { teethToUpdate: Tooth[]; teethInJaw: Tooth[] }): Tooth[] {
		return this.bridgeService.getBridgeTeethToUpdate({
			teethToUpdate,
			teethInJaw,
			shouldConvertDeletingToregular: this.isProcedureFlow
		});
	}

	validateBridge({ teeth }: { teeth: Tooth[] }): BridgeValidationState {
		if (this.isProcedureFlow) {
			return this.bridgeValidatorService.validateBridgeForProcedureFlow(teeth);
		}

		return this.bridgeValidatorService.validateBridgeForCaseTypeFlow(teeth);
	}

	isPrep = ({ tooth }: { tooth: Tooth }): boolean => {
		const isBridgeTooth = !!tooth.BridgeIndex;
		if (!tooth.UnitTypeID && !tooth.ToothInBridgeTypeID) {
			return false;
		}
		const isPrepTooth = ![
			UnitTypes.Missing_edentulousSpace,
			UnitTypes.Missing_noSpace,
			UnitTypes.Regular,
			UnitTypes.Detachable
		].includes(tooth.UnitTypeID);
		const isPrepBridgeTooth = ![
			UnitTypesInBridge.Skipped,
			UnitTypesInBridge.Missing,
			UnitTypesInBridge.MarylandAbutment,
			UnitTypesInBridge.Pontic
		].includes(tooth.ToothInBridgeTypeID);
		return isBridgeTooth ? isPrepBridgeTooth : isPrepTooth;
	};

	getUnitTypeTranslation({ tooth, isBridgeContext }: { tooth: Tooth; isBridgeContext }): string {
		const isBridgeTooth = !!tooth.BridgeIndex;
		return this.translateService.instant(
			isBridgeContext
				? `BridgeUnitTypes.${
						UnitTypesInBridge[
							isBridgeTooth ? tooth.ToothInBridgeTypeID : this.convertToBridgeToothUnitType({ unitType: tooth.UnitTypeID })
						]
				  }`
				: `UnitTypes.${
						UnitTypes[
							isBridgeTooth
								? this.convertFromBridgeToothUnitType({ unitTypeInBridge: tooth.ToothInBridgeTypeID })
								: tooth.UnitTypeID
						]
				  }`
		);
	}

	getToothByToothId({ toothId }: { toothId: number }): Observable<Tooth> {
		return this.teethDiagramQuery.teeth$.pipe(
			map(teeth => {
				const allTeeth: Tooth[] = [...teeth.upperJaw, ...teeth.lowerJaw];
				return allTeeth.find(tooth => tooth.ToothID === toothId);
			})
		);
	}

	forceUpdateTeeth({ teethToUpdate }: { teethToUpdate: Tooth[] }) {
		this.teethDiagramStore.updateTeeth({ teethToUpdate });
	}

	isPonticMDAbutmentOrMissing(unitType: UnitTypesInBridge): boolean {
		return this.bridgeService.isPonticMDAbutmentOrMissing(unitType);
	}

	isToothMissingOrRegular(unitType: UnitTypes): boolean {
		return [UnitTypes.Missing, UnitTypes.Missing_edentulousSpace, UnitTypes.Missing_noSpace, UnitTypes.Regular].includes(unitType);
	}

	checkIsScanBody({ UnitTypeID, ToothInBridgeTypeID }: Tooth): boolean {
		return !this.isProcedureFlow && (UnitTypeID === UnitTypes.ScanBody || ToothInBridgeTypeID === UnitTypesInBridge.ScanBody);
	}

	checkIsImplantBased({ UnitTypeID, ToothInBridgeTypeID, BridgeIndex }: Tooth): boolean {
		const isImplantBased = BridgeIndex ? ToothInBridgeTypeID === UnitTypesInBridge.ImplantBased : UnitTypeID === UnitTypes.ImplantBased;
		return this.isProcedureFlow && isImplantBased;
	}

	checkIsImplantPosition({ UnitTypeID }: Tooth): boolean {
		return this.isProcedureFlow && UnitTypeID === UnitTypes.ImplantPosition;
	}

	private getPreparationDesignName({ preparationDesignId }: { preparationDesignId: string }): string {
		const preparationDesigns: IdName[] = this.getPreparationDesignOptionsMapping();
		return preparationDesigns?.find(preparationDesignOption => preparationDesignOption.Id === preparationDesignId)?.Name;
	}

	private getMarginDesignName({ marginDesignId }: { marginDesignId: string }): string {
		const marginDesigns: IdName[] = this.getMarginDesignOptions();
		return marginDesigns?.find(marginDesignOption => marginDesignOption.Id === marginDesignId)?.Name;
	}

	private getProcedureFlowUnitTypeNames(tooth: Tooth): Observable<IdName[]> {
		return this.rxRulesService.getProcedureFlowUnitTypeNames(tooth);
	}

	private getCaseTypeFlowUnitTypeNames(tooth: Tooth): Observable<IdName[]> {
		return this.rxRulesService.getCaseTypeFlowUnitTypeNames(tooth);
	}

	private getProcedureFlowUnitTypeNamesForBridge(tooth: Tooth): Observable<IdName[]> {
		return combineLatest([this.orderQuery.procedureMap$, this.teeth$]).pipe(
			take(1),
			map(([procedureMap, teethInBridge]) => {
				const isOuterTooth = this.bridgeService.isOuterTooth(tooth, teethInBridge);
				return this.rxRulesService.getProcedureFlowUnitTypeNamesForBridge(procedureMap.Id, tooth, isOuterTooth);
			})
		);
	}

	private getUpdatedTooth({
		tooth,
		crownData,
		abutmentData,
		implantTypeId
	}: {
		tooth: Tooth;
		crownData?: CrownData;
		abutmentData: AbutmentData;
		implantTypeId?: number;
	}) {
		if (!tooth) {
			return tooth;
		}
		const updatedTooth = tooth;
		const defaultTooth = getNewTooth();

		if (!!crownData) {
			updatedTooth.MaterialID = crownData.material;
			updatedTooth.PreparationDesignBuccalId = crownData.preparationDesignBuccal;
			updatedTooth.PreparationDesignBuccal = this.getPreparationDesignName({
				preparationDesignId: crownData.preparationDesignBuccal
			});
			updatedTooth.PreparationDesignLingualId = crownData.preparationDesignLingual;
			updatedTooth.PreparationDesignLingual = this.getPreparationDesignName({
				preparationDesignId: crownData.preparationDesignLingual
			});
			updatedTooth.MarginDesignBuccalId = crownData.marginDesignBuccal;
			updatedTooth.MarginDesignBuccal = this.getMarginDesignName({ marginDesignId: crownData.marginDesignBuccal });
			updatedTooth.MarginDesignLingualId = crownData.marginDesignLingual;
			updatedTooth.MarginDesignLingual = this.getMarginDesignName({ marginDesignId: crownData.marginDesignLingual });
			updatedTooth.ShadeSystemId = crownData.shadeSystem;
			updatedTooth.ShadeIncisal = crownData.incisal;
			updatedTooth.ShadeBody = crownData.body;
			updatedTooth.ShadeGingival = crownData.gingival;
			updatedTooth.StumpfShade = crownData.stumpShade;
			updatedTooth.SpecificationId = crownData?.specification ?? defaultTooth.SpecificationId;
			updatedTooth.PonticDesignID = crownData.ponticDesign ?? defaultTooth.PonticDesignID;
		}
		if (!!abutmentData) {
			updatedTooth.AbutmentType = abutmentData.abutmentType;
			updatedTooth.AbutmentMaterialId = abutmentData.abutmentMaterial;
			updatedTooth.CAMaterialID = abutmentData.abutmentMaterial;
			updatedTooth.AbutmentMarginStyle = this.getAbutmentMarginStyleName({ marginDesignId: abutmentData.marginStyle });
			updatedTooth.AbutmentMarginStyleId = abutmentData.marginStyle;
			updatedTooth.CAMarginStyle = this.getAbutmentMarginStyleName({ marginDesignId: abutmentData.marginStyle });
			updatedTooth.CAMarginStyleId = abutmentData.marginStyle;
		}
		if (implantTypeId || implantTypeId === 0) {
			updatedTooth.ImplantTypeID = implantTypeId;
		}
		return updatedTooth;
	}
}
