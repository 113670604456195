import { Injectable } from '@angular/core';
import { DoctorStore } from './state/doctor-store';
import { DoctorQuery } from './state/doctor-query';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggerService } from '@core/services/logger/logger.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ShellStore } from '@shared/store/shell/shell-store';
import { Doctor } from '@shared/models/doctor';

@Injectable()
export class DoctorFacade {

	doctor$: Observable<Doctor> = this.query.doctor$;
	isLicenseEditable$: Observable<boolean> = this.query.isLicenseEditable$;
	isReadOnly$: Observable<boolean> = this.shellQuery.getReadOnlyState('doctor');
	isRxValidForSave$: Observable<boolean> = this.shellQuery.isRxValidForSave$;

	readonly facadeName = 'DoctorFacade';

	constructor(
		private store: DoctorStore,
		private query: DoctorQuery,
		private shellStore: ShellStore,
		private shellQuery: ShellQuery,
		private logger: LoggerService
	) {}

	getDoctorInformation(): Observable<Doctor> {
		return this.shellQuery.doctor$
			.pipe(
				tap((doctor: Doctor) => {
					if (!!doctor) {
						this.store.update({ doctor });
						this.logger.info(`${this.facadeName}: ${doctor?.Name} is loaded`, { module: this.facadeName });
					} else {
						this.logger.warn(`${this.facadeName}: doctor failed to load`, { module: this.facadeName });
					}
				})
			)
	}

	updateLicense({ license }: { license: string }): void {
		this.shellStore.update(state => {
			return { ...state, userSettings: { ...state.userSettings, LicenseNumber: license } }
		});
	}
}
