import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LoggerService } from '@core/services/logger/logger.service';
import { AwarenessFacade } from '@modules/awareness/awareness.facade';

@Component({
	selector: 'rx-awareness-banner',
	templateUrl: './awareness-banner.component.html',
	styleUrls: ['./awareness-banner.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AwarenessBannerComponent {
	private static componentName = 'AwarenessBannerComponent';

	@ViewChild('miniPopup')
	miniPopup: TemplateRef<unknown>;

	linkToTheLearningCenter$ = this.awarenessFacade.linkToTheLearningCenter$;
	showLearningCenterPart$ = this.awarenessFacade.showLearningCenterPart$;

	constructor(private matDialog: MatDialog, private loggerService: LoggerService, private awarenessFacade: AwarenessFacade) {}

	openPopup(): Observable<unknown> {
		this.loggerService.info(`Awareness QR was opened from banner, when procedure flow is ${String(this.isProcedureFlow)}`, {
			module: AwarenessBannerComponent.componentName
		});
		return this.matDialog.open(this.miniPopup).afterClosed();
	}

	get isProcedureFlow(): boolean {
		return this.awarenessFacade.isProcedureFlow;
	}
}
