import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { PopupIconNames } from '@shared/models/enums/popup-icon.enum';
import { IdName } from '@shared/models/id-name';
import { MaterialOption } from '@shared/models/material-option';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { PopupService } from '@shared/services/popup.service';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, pairwise, takeUntil, tap, shareReplay, filter } from 'rxjs/operators';
import { ShadeSystem } from '@shared/models/shade-system';
import { Specification } from '@shared/models/specification';
import { SpecIdWithMaterialIds } from '@shared/models/rx-rules-json-interface';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';
import { LoggerService } from '@core/services/logger/logger.service';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { unitTypesWithoutDetails } from '@shared/models/consts';

@Component({
	selector: 'rx-crown-section',
	styleUrls: ['./crown-section.component.scss'],
	templateUrl: 'crown-section.component.html',
	providers: [ToothEditorFacade],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrownSectionComponent extends BaseDestroyableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	readonly maxInputLengthForOtherShadeSystem = 10;
	isOtherShadeSystemSelected: boolean;
	isMarginDesignEnabled = false;
	private allowToothReset = false;
	private toothResetTimeout;
	private readonly componentName = 'CrownSectionComponent';

	@Input() toothClickedOn: Tooth;
	@Input() isReadOnly: boolean;

	stumpShadeOptions: IdName[] = this.toothEditorFacade.getStumpShadeOptions();
	preparationDesignOptions: IdName[];
	shadeSystemOptions: ShadeSystem[];
	incisalBodyGingivalOptions: IdName[];

	materialOptions: IdName[];
	specifications: Specification[];

	shouldValidateForSend$: Observable<boolean> = this.toothEditorFacade.shouldValidateForSend$;

	private isPopUpEnabled = true;

	marginDesignOptions: IdName[] = this.toothEditorFacade.getMarginDesignOptions();

	get isToothClickedOnAPrep() {
		return this.toothEditorFacade.isPrep({ tooth: this.toothClickedOn });
	}

	isCrownSectionDisabled$ = this.toothEditorFacade.toothClickedOn$.pipe(
		filter(tooth => !!tooth),
		map(tooth => this.checkIsCrownSectionDisabled(tooth)),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	isPonticIndicationSelected$: Observable<boolean> = this.toothEditorFacade.toothClickedOn$.pipe(
		filter(tooth => !!tooth),
		map(tooth => this.checkPonticIndication(tooth))
	);

	materialOption: MaterialOption;

	ponticDesigns: ConfigurationItem[];

	formChangingDestroy$ = new Subject<void>();

	expandAdditionalInfo = false;

	crownSectionForm = new FormGroup({
		specification: new FormControl({ value: null, disabled: false }),
		material: new FormControl({ value: null, disabled: false }),
		preparationDesignBuccal: new FormControl({ value: null, disabled: false }),
		preparationDesignLingual: new FormControl({ value: null, disabled: false }),
		marginDesignBuccal: new FormControl({ value: null, disabled: false }),
		marginDesignLingual: new FormControl({ value: null, disabled: false }),
		shadeSystem: new FormControl({ value: null, disabled: false }),
		incisal: new FormControl({ value: null, disabled: false }),
		body: new FormControl({ value: null, disabled: false }),
		gingival: new FormControl({ value: null, disabled: false }),
		stumpShade: new FormControl({ value: null, disabled: false }),
		ponticDesign: new FormControl()
	});

	get specificationControl(): AbstractControl {
		return this.crownSectionForm.controls.specification;
	}
	get materialControl(): AbstractControl {
		return this.crownSectionForm.controls.material;
	}
	get preparationDesignBuccalControl(): AbstractControl {
		return this.crownSectionForm.controls.preparationDesignBuccal;
	}
	get preparationDesignLingualControl(): AbstractControl {
		return this.crownSectionForm.controls.preparationDesignLingual;
	}
	get marginDesignBuccalControl(): AbstractControl {
		return this.crownSectionForm.controls.marginDesignBuccal;
	}
	get marginDesignLingualControl(): AbstractControl {
		return this.crownSectionForm.controls.marginDesignLingual;
	}
	get shadeSystemControl(): AbstractControl {
		return this.crownSectionForm.controls.shadeSystem;
	}
	get incisalControl(): AbstractControl {
		return this.crownSectionForm.controls.incisal;
	}
	get bodyControl(): AbstractControl {
		return this.crownSectionForm.controls.body;
	}
	get gingivalControl(): AbstractControl {
		return this.crownSectionForm.controls.gingival;
	}
	get stumpShadeControl(): AbstractControl {
		return this.crownSectionForm.controls.stumpShade;
	}

	get ponticDesignControl() {
		return this.crownSectionForm.get('ponticDesign');
	}

	get isShadeDisabled() {
		return !this.materialOption?.ShadeVisible;
	}

	constructor(private toothEditorFacade: ToothEditorFacade, private popupService: PopupService, private logger: LoggerService) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const isDifferentTooth = changes.toothClickedOn.currentValue.ToothID !== changes.toothClickedOn.previousValue?.ToothID;
		const isDifferentUnitType = changes.toothClickedOn.currentValue.UnitTypeID !== changes.toothClickedOn.previousValue?.UnitTypeID;
		const isDifferentUnitTypeInBridge =
			changes.toothClickedOn.currentValue.ToothInBridgeTypeID !== changes.toothClickedOn.previousValue?.ToothInBridgeTypeID;

		if (isDifferentTooth || isDifferentUnitType || isDifferentUnitTypeInBridge || this.allowToothReset) {
			this.logger.info(`Crown section Inputs changed, see here: ${JSON.stringify(changes, null, 2)}`, { module: this.componentName });
			this.incisalControl.patchValue(null);
			this.bodyControl.patchValue(null);
			this.bodyControl.markAsTouched();
			this.gingivalControl.patchValue(null);
			this.formChangingDestroy$.next();
			this.setForm();
			this.subscribeToFormChanges();
			this.allowToothReset = false;
			this.isOtherShadeSystemSelected = (changes.toothClickedOn.currentValue as Tooth)?.ShadeSystemId === -1;
		}

		if (changes.isReadOnly?.currentValue) {
			this.toothEditorFacade.setFormGroupReadOnlyMode(this.crownSectionForm);
		}
	}

	ngOnInit() {
		this.subscribeToShadeSystemChanges();
		this.subscribeToSpecificationChanges();
		this.subscribeToMaterialChanges();
		this.checkAdditionalInfo();
	}

	ngAfterViewInit() {
		this.formChangingDestroy$.next();
		this.setForm();
		this.subscribeToFormChanges();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		clearTimeout(this.toothResetTimeout);
		this.formChangingDestroy$.complete();
	}

	checkIsCrownSectionDisabled(tooth: Tooth): boolean {
		if (tooth.BridgeIndex) {
			return this.toothEditorFacade.isGlidewellOrder || tooth.ToothInBridgeTypeID === UnitTypesInBridge.Missing;
		}

		return this.toothEditorFacade.isGlidewellOrder || unitTypesWithoutDetails.includes(tooth.UnitTypeID);
	}

	clearControl(control: AbstractControl): void {
		control.patchValue(null);
	}

	private clearShadeValuesAndSetOptions(controlValue: IdName) {
		this.incisalControl.patchValue(null);
		this.bodyControl.patchValue(null);
		this.gingivalControl.patchValue(null);
		this.setIncisalBodyGingivalOptions();
		this.isOtherShadeSystemSelected = controlValue?.Id === -1;
	}

	private subscribeToShadeSystemChanges() {
		this.shadeSystemControl.valueChanges
			.pipe(
				distinctUntilChanged(),
				pairwise(),
				tap(([prevShadeControlValue, nextShadeControlValue]: [IdName, IdName]) => {
					if (nextShadeControlValue) {
						if (this.incisalControl.value || this.bodyControl.value || this.gingivalControl.value) {
							if (!this.isPopUpEnabled) {
								return;
							}
							const popUpInput = {
								titleTranslationKey: 'Popup.Confirmation',
								contentTranslationKey: 'ToothEditor.ShadeSystemWarning',
								iconName: PopupIconNames.Question
							};
							this.popupService
								.openConfirmationPopUp({ popUpInput })
								.pipe(takeUntil(this.componentAlive$))
								.subscribe(result => {
									switch (result) {
										case PopUpActions.Cancel:
											this.isPopUpEnabled = false;
											this.shadeSystemControl.patchValue(prevShadeControlValue);
											this.isPopUpEnabled = true;
											break;
										case PopUpActions.Ok:
											this.clearShadeValuesAndSetOptions(nextShadeControlValue);
											break;
									}
								});
						} else {
							this.clearShadeValuesAndSetOptions(nextShadeControlValue);
						}
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToFormChanges() {
		this.crownSectionForm.valueChanges
			.pipe(
				debounceTime(200),
				distinctUntilChanged(),
				tap(() => this.updateToothFromForm()),
				takeUntil(merge(this.componentAlive$, this.formChangingDestroy$))
			)
			.subscribe();
	}

	private subscribeToSpecificationChanges() {
		this.specificationControl.valueChanges
			.pipe(
				tap(() => {
					this.materialOptions = this.toothEditorFacade.getMaterialsBySpecification(
						this.specificationControl.value?.Id,
						this.getSpecIdsWithMaterialIds()
					);
					this.updateToothFromForm();
					this.updateMaterial();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToMaterialChanges() {
		this.materialControl.valueChanges
			.pipe(
				tap(() => {
					this.updateMaterial();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private updateMaterial() {
		this.setMaterialOption();
		this.setPreparationDesignOptions();
		this.setPrepAndMarginDesigns();
		this.setShadeSystemOptions();
	}

	private setForm() {
		this.setSpecificationSelection();
	}

	private getSpecIdsWithMaterialIds(): SpecIdWithMaterialIds[] {
		return !!this.toothClickedOn?.BridgeIndex
			? this.toothEditorFacade.getSpecIdsWithMaterialIdsForBridge(
					this.toothEditorFacade.procedureMap.Id,
					this.toothClickedOn.ToothID,
					this.toothClickedOn.ToothInBridgeTypeID
			  )
			: this.toothEditorFacade.getSpecIdsWithMaterialIds(
					this.toothEditorFacade.procedureMap.Id,
					this.toothClickedOn.ToothID,
					this.toothClickedOn.UnitTypeID
			  );
	}

	private setSpecificationSelection() {
		const specIdsWithMaterialIds = this.getSpecIdsWithMaterialIds();

		this.specifications = this.toothEditorFacade.getFilteredSpecifications(specIdsWithMaterialIds.map(({ Id }) => Id));
		this.specificationControl.patchValue(this.getSpecificationFromOptions(), { emitEvent: false });

		this.materialOptions = this.toothEditorFacade.getMaterialsBySpecification(
			this.specificationControl.value?.Id,
			specIdsWithMaterialIds
		);
		this.materialControl.patchValue(this.getMaterialFromOptions(), { emitEvent: false });

		this.ponticDesigns = this.toothEditorFacade.ponticDesigns;
		this.ponticDesignControl.patchValue(this.getPonticFromOptions());

		this.setMaterialOption();
		this.setPreparationDesignOptions();
		this.setPrepAndMarginDesigns();
		this.setShadeSystemOptions();
		this.setIncisalBodyGingivalOptions();
		this.setShadeSystemSelections();
		this.setStumpShadeSelection();

		this.updateToothFromForm();
	}

	private getSpecificationFromOptions(): Specification {
		if (![-1, null, undefined].includes(this.toothClickedOn?.SpecificationId)) {
			return this.specifications.find(({ Id }) => Id === this.toothClickedOn.SpecificationId) ?? null;
		}
		this.logger.info(`Specification of current tooth (#${this.toothClickedOn?.ToothID}) is ${this.toothClickedOn?.SpecificationId}`, {
			module: this.componentName
		});

		return null;
	}

	private getMaterialFromOptions(): IdName {
		if (this.toothClickedOn?.MaterialID) {
			return this.materialOptions?.find(({ Id }) => Id === this.toothClickedOn.MaterialID) ?? null;
		}
		this.logger.info(`Material of current tooth (#${this.toothClickedOn?.ToothID}) is ${this.toothClickedOn?.MaterialID}`, {
			module: this.componentName
		});

		return null;
	}

	private getPonticFromOptions(): ConfigurationItem {
		if (this.checkPonticIndication(this.toothClickedOn)) {
			const ponticDesignID = this.toothClickedOn?.PonticDesignID;

			if (ponticDesignID) {
				return this.ponticDesigns.find(({ Id }) => Id === ponticDesignID) ?? null;
			}

			return null;
		}
		this.logger.info(`current tooth (#${this.toothClickedOn?.ToothID}) is not pontic or it is not procedure flow`, {
			module: this.componentName
		});

		return null;
	}

	private setShadeSystemSelections() {
		let incisalSelection;
		let bodySelection;
		let gingivalSelection;

		if (this.isOtherShadeSystemSelected) {
			incisalSelection = this.toothClickedOn.ShadeIncisal;
			bodySelection = this.toothClickedOn.ShadeBody;
			gingivalSelection = this.toothClickedOn.ShadeGingival;
		} else {
			incisalSelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeIncisal
			);
			bodySelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeBody
			);
			gingivalSelection = this.incisalBodyGingivalOptions?.find(
				incisalBodyGingivalOption => incisalBodyGingivalOption.Id === this.toothClickedOn.ShadeGingival
			);
		}
		this.incisalControl.patchValue(incisalSelection || null);
		this.bodyControl.patchValue(bodySelection || null);
		this.gingivalControl.patchValue(gingivalSelection || null);
	}

	private setStumpShadeSelection() {
		const stumpShadeOption = this.stumpShadeOptions.find(stumpShade => stumpShade?.Id === this.toothClickedOn.StumpfShade);
		this.stumpShadeControl.patchValue(stumpShadeOption || null);
	}

	private updateToothFromForm() {
		const crownSectionFormChanges = this.crownSectionForm.getRawValue();
		for (const [key, value] of Object.entries(crownSectionFormChanges)) {
			if (typeof value === 'string') {
				// handling gingival, incisal and body values when selecting 'other' shade system
				crownSectionFormChanges[key] = value;
			} else {
				crownSectionFormChanges[key] = value !== null ? crownSectionFormChanges[key]?.Id : null;
			}
		}

		if (!!this.toothClickedOn.BridgeIndex) {
			this.toothEditorFacade.updateToothAndSharedFieldsInBridge({
				tooth: this.toothClickedOn,
				crownData: { ...crownSectionFormChanges }
			});
		} else {
			this.toothEditorFacade.updateTooth({ tooth: this.toothClickedOn, crownData: { ...crownSectionFormChanges } });
		}
	}

	private setMaterialOption() {
		this.materialOption = this.toothEditorFacade.getMaterialOption(this.materialControl.value?.Id);
	}

	private setPreparationDesignOptions() {
		this.preparationDesignOptions = this.toothEditorFacade.mapPreparationDesignOptions(this.materialOption?.PrepDesignValues);
	}

	private setPrepAndMarginDesigns() {
		const preparationDesignBuccalSelection = this.preparationDesignOptions?.find(
			prepDesign => prepDesign.Id === this.toothClickedOn.PreparationDesignBuccalId
		);
		const preparationDesignLingualSelection = this.preparationDesignOptions?.find(
			prepDesign => prepDesign.Id === this.toothClickedOn.PreparationDesignLingualId
		);
		const marginDesignBuccalSelection = this.marginDesignOptions?.find(
			marginDesign => marginDesign.Id === this.toothClickedOn.MarginDesignBuccalId
		);
		const marginDesignLingualSelection = this.marginDesignOptions?.find(
			marginDesign => marginDesign.Id === this.toothClickedOn.MarginDesignLingualId
		);
		this.preparationDesignBuccalControl.patchValue(preparationDesignBuccalSelection || null);
		this.preparationDesignLingualControl.patchValue(preparationDesignLingualSelection || null);
		this.marginDesignBuccalControl.patchValue(marginDesignBuccalSelection || null);
		this.marginDesignLingualControl.patchValue(marginDesignLingualSelection || null);
	}

	private setShadeSystemOptions() {
		const materialId = this.materialControl?.value?.Id;
		this.shadeSystemOptions = this.toothEditorFacade.getShadeSystemOptionsByMaterialId({ materialId });

		if (!this.shadeSystemOptions) {
			this.shadeSystemOptions = this.toothEditorFacade.getDefaultShadeSystemOptions();
			const userSettingsShadeSystemOption = this.toothEditorFacade.getDefaultShadeSystemFromUserSettings({
				defaultShadeSystemOptions: this.shadeSystemOptions
			});
			const shadeSystemSelection = this.shadeSystemOptions.find(option => option.Id === this.toothClickedOn.ShadeSystemId);

			this.isOtherShadeSystemSelected = shadeSystemSelection?.Id === -1;
			this.shadeSystemControl.patchValue(shadeSystemSelection || userSettingsShadeSystemOption || this.shadeSystemOptions[0]);
		} else {
			const defaultShadeSystemSelectionId = this.toothEditorFacade.getDefaultShadeSystemSelection({ materialId });
			const defaultShadeSystemSelection =
				defaultShadeSystemSelectionId === -2
					? null
					: this.shadeSystemOptions?.find(shadeSystemOption => shadeSystemOption?.Id === defaultShadeSystemSelectionId);
			this.shadeSystemControl.patchValue(defaultShadeSystemSelection);
		}
	}
	// TODO: refactor: no need to parse it when we have options in shadeSystemOptions prop
	private setIncisalBodyGingivalOptions() {
		this.incisalBodyGingivalOptions = this.toothEditorFacade.getIncisalBodyGingivalOptions({
			materialId: this.materialControl?.value?.Id,
			shadeSystemId: this.shadeSystemControl?.value?.Id
		});
	}

	private checkPonticIndication(tooth: Tooth): boolean {
		return tooth?.ToothInBridgeTypeID === UnitTypesInBridge.Pontic;
	}

	private checkAdditionalInfo() {
		this.expandAdditionalInfo =
			this.preparationDesignBuccalControl.value ||
			this.preparationDesignLingualControl.value ||
			this.marginDesignBuccalControl.value ||
			this.marginDesignLingualControl.value ||
			this.incisalControl.value ||
			this.gingivalControl.value;
	}
}
