import { Inject, Injectable } from '@angular/core';
import { CountryRegionRule, Region } from '@shared/models/country-region-rule';
import { FeatureToggleSettings, SoftwareOptions } from '@shared/models/enums/enums';
import { COUNTRY_REGIONS_RULES } from '@shared/rules/rules-tokens';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable({
	providedIn: 'root'
})
export class AwarenessQuery {
	constructor(
		private shellQuery: ShellQuery,
		private softwareOptionsService: SoftwareOptionsService,
		@Inject(COUNTRY_REGIONS_RULES) private countries: CountryRegionRule,
		private hostPlatformService: HostPlatformService
	) {}

	region$: Observable<Region> = this.shellQuery.companyCountryCode$.pipe(
		filter(code => !!code),
		map(code => this.getRegionByCountryCode(code)),
		shareReplay({ bufferSize: 1, refCount: true })
	);
	linkToTheLearningCenter$: Observable<string> = this.region$.pipe(map(region => region.Link));
	hasAwarenessMessageLmrSwo$ = this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.RxAwarenessMessageLMR);
	hasAwarenessParticipateLmrSwo$ = this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.RxAwarenessParticipateLMR);

	isChina$ = this.region$.pipe(map(region => region.Name === 'China'));
	isHostPlatformScanner$ = this.hostPlatformService.isScanner$;
	showLearningCenterPart$ = combineLatest([this.isChina$, this.isHostPlatformScanner$]).pipe(
		map(([isChina, isHostPlatformScanner]) => !(isChina || isHostPlatformScanner))
	);
	hasGaFeatureFlag$: Observable<boolean> = this.shellQuery.featureToggles$.pipe(
		map(featureToggles => featureToggles?.find(f => f.id === FeatureToggleSettings.RxAwarenessMessageGa)?.isActive)
	);

	showLmrAwareness$: Observable<boolean> = combineLatest([this.hasAwarenessMessageLmrSwo$, this.shellQuery.isNewRx$]).pipe(
		map(([hasAwarenessMessageLmrSwo, isNewRx]) => hasAwarenessMessageLmrSwo && isNewRx)
	);
	showGaAwareness$: Observable<boolean> = combineLatest([
		this.shellQuery.isNewRx$,
		this.hasAwarenessParticipateLmrSwo$,
		this.hasAwarenessMessageLmrSwo$,
		this.hasGaFeatureFlag$
	]).pipe(
		map(([isNewRx, hasAwarenessParticipateLmrSwo, hasAwarenessMessageLmrSwo, hasGaFeatureFlag]) => {
			return hasGaFeatureFlag && isNewRx && !hasAwarenessParticipateLmrSwo && !hasAwarenessMessageLmrSwo;
		})
	);

	private getRegionByCountryCode(countryCode: string): Region {
		return this.countries.Regions.find(region => {
			return region.Countries.find(country => country.Code.toLowerCase() === countryCode.toLowerCase());
		});
	}
}
