import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LocalSettings } from '@shared/models/local-settings';

export const localSettingsStoreName = 'localSettings';

export interface LocalSettingsState {
	userSettings: Record<number, LocalSettings>;
}

export const getInitialState = (): LocalSettingsState => {
	return {
		userSettings: {}
	};
};

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: localSettingsStoreName })
export class LocalSettingsStore extends Store<LocalSettingsState> {
	constructor() {
		super(getInitialState());
	}

	updateLocalSettings(id: number, settings: LocalSettings): void {
		let userSettings = this.getValue().userSettings;
		if (userSettings[id]){
			settings = { ...userSettings[id], ...settings }
		}
		userSettings = { ...userSettings, [id]: settings };
		this.update({ userSettings });
	}
}
