import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';

@Injectable({ providedIn: 'root' })
export class RxForLabApiService {

	constructor(  
		private apiService: ApiService
	) { }

	saveRx(rx: RxModel, orderId: number) {
		const body = { 
			rxFormJson: JSON.stringify(rx),
			orderId
		};
		const config: IApiCall = {
			selector: 'saveRxForLab',
			options: { body }
		};
		return this.apiService.request(config);
	}
}
