import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { AwarenessQuery } from '@modules/awareness/awareness.query';

@Injectable({
	providedIn: 'root'
})
export class AwarenessFacade {
	linkToTheLearningCenter$ = this.awarenessQuery.linkToTheLearningCenter$;
	showLearningCenterPart$ = this.awarenessQuery.showLearningCenterPart$;

	constructor(private shellQuery: ShellQuery, private awarenessQuery: AwarenessQuery) {}

	get isProcedureFlow(): boolean {
		return this.shellQuery.isProcedureFlow;
	}
}
