import { Injectable, Renderer2 } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ShellQuery } from '@shared/store/shell/shell-query';

@Injectable()
export class PrintService {
	constructor(private renderer: Renderer2, private shellQuery: ShellQuery) {}

	private iframeElementName = 'auto-print-rx-iframe';

	createPrintIframeElement(): Observable<HTMLIFrameElement> {
		const iframeElement = this.renderer.createElement('iframe');

		this.addPrintIframeExternalStyles(iframeElement);
		this.addPrintIframeExternalAttrs(iframeElement);

		this.renderer.appendChild(this.getNodeForPrintAttach(), iframeElement);

		return this.getIframeReady(iframeElement).pipe(
			switchMap(printIframeElement => this.loadPrintIframeInternalStyles(printIframeElement))
		);
	}

	private addPrintIframeExternalStyles(iframeElement: HTMLIFrameElement): void {
		const style = {
			width: '100vh',
			height: '100vh',
			left: '0',
			top: '0',
			position: 'absolute', // for debugging print layout in standalone mode comment the 'position' property.
			zIndex: '-2',
			display: 'block', // for the iframe load correctly it's mandatory for the display css option remain 'block' / default.
			visibility: 'hidden' // for debugging print layout in standalone mode comment the 'visibility' property.
		};

		Object.entries<string>(style).forEach(([key, value]) => this.renderer.setStyle(iframeElement, key, value));
	}

	private addPrintIframeExternalAttrs(iframeElement: HTMLIFrameElement): void {
		this.renderer.setAttribute(iframeElement, 'frameborder', '0');
		this.renderer.setAttribute(iframeElement, 'name', this.iframeElementName);
	}

	private loadPrintIframeInternalStyles(iframeElement: HTMLIFrameElement): Observable<HTMLIFrameElement> {
		const link = this.renderer.createElement('link');

		this.renderer.setAttribute(link, 'type', 'text/css');
		this.renderer.setAttribute(link, 'rel', 'stylesheet');
		this.renderer.setAttribute(link, 'href', `${this.shellQuery.staticFilesEndpoint}/print.css`);
		this.renderer.setAttribute(link, 'crossorigin', 'anonymous');

		this.renderer.appendChild(iframeElement.contentDocument.head, link);

		return fromEvent(link, 'load').pipe(
			take(1),
			map(() => iframeElement)
		);
	}

	private getIframeReady(iframeElement: HTMLIFrameElement): Observable<HTMLIFrameElement> {
		if (iframeElement.contentDocument.readyState === 'complete') {
			// chrome loads iframe instantly, 'load' event does not work:
			// https://stackoverflow.com/questions/20572734/load-event-not-firing-when-iframe-is-loaded-in-chrome
			return of(iframeElement);
		} else {
			// mozilla loads iframe asynchronously
			return fromEvent(iframeElement, 'load').pipe(
				take(1),
				map(() => iframeElement)
			);
		}
	}

	private getNodeForPrintAttach(): Node {
		const rxForLabSelector = document.body.querySelector('eup-rx-app-for-lab'); // viewer page
		const rxAppIframeSelector = document.querySelectorAll('#print-rx-app-iframe'); // orders lab dashboard
		const rxAppSelector =
			document.querySelector('rx-app') ||
			(rxAppIframeSelector[rxAppIframeSelector.length - 1] as HTMLIFrameElement).contentDocument.body.querySelector('rx-app'); // rx page

		return rxForLabSelector?.classList?.contains('hidden') ? rxForLabSelector.parentNode : rxAppSelector;
	}
}
