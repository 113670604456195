import { OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { IdName } from '@shared/models/id-name';
import { getSortedListByName } from '@shared/services/sort-list-by-name-util';
import { Observable } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { ErrorStateMatcherForInstantDisplay } from '@shared/components/simple-selector/simple-selector.component';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { ImplantService } from '@modules/tooth-editor/services/implant.service';

const ALIGNED_RIGHT_POSITIONS: ConnectedPosition[] = [
	{
		originX: 'end',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'top'
	},
	{
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'bottom'
	}
];

@Component({
	selector: 'rx-manufacture-section',
	templateUrl: './manufacture-section.component.html',
	styleUrls: ['./manufacture-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManufactureSectionComponent extends BaseDestroyableComponent implements OnInit, OnChanges {
	@Input() toothClickedOn: Tooth;
	@Input() isReadOnly: boolean;

	@ViewChild(MatSelect) set setup(matSelect: MatSelect) {
		if (matSelect && matSelect.id === this.implantTypeScanBodyId) {
			// eslint-disable-next-line no-underscore-dangle
			matSelect._positions = ALIGNED_RIGHT_POSITIONS;
		}
	}

	implantTypeScanBodyId = 'rx-auto-select-implant-type-scan-body';

	matcher = new ErrorStateMatcherForInstantDisplay();

	scanBodyManufacturerOptions$: Observable<IdName[]> = this.implantService.scanBodyManufacturerOptions$.pipe(
		map(scanBodyManufacturers => getSortedListByName(this.implantService.filterActiveScanBodyManufacturers({ scanBodyManufacturers })))
	);

	implantManufacturerOptions: IdName[] = [];
	implantTypeScanBodyOptions: IdName[] = [];
	shouldValidateForSend$: Observable<boolean> = this.toothEditorFacade.shouldValidateForSend$.pipe(
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	manufactureForm: FormGroup = this.formBuilder.group({
		scanBodyManufacturer: null,
		implantManufacturer: null,
		implantTypeScanBody: null
	});

	get scanBodyManufacturerControl(): AbstractControl {
		return this.manufactureForm.controls.scanBodyManufacturer;
	}
	get implantManufacturerControl(): AbstractControl {
		return this.manufactureForm.controls.implantManufacturer;
	}
	get implantTypeScanBodyControl(): AbstractControl {
		return this.manufactureForm.controls.implantTypeScanBody;
	}

	constructor(private toothEditorFacade: ToothEditorFacade, private formBuilder: FormBuilder, private implantService: ImplantService) {
		super();
	}

	ngOnChanges({ toothClickedOn }: SimpleChanges): void {
		if (toothClickedOn.currentValue?.ToothID !== toothClickedOn.previousValue?.ToothID) {
			this.initForm();
		}
	}

	ngOnInit(): void {
		this.initControlSubscriptions();
	}

	compareIds(item1: any, item2: any): boolean {
		if (!item1 || !item2) {
			return false;
		}
		return item1.Id === item2.Id;
	}

	generateOptionId(option: IdName, label: string): string {
		return 'simple-select-option-' + label?.toLowerCase().replace(' ', '-') + '-' + option?.Name?.replace(/[\W_]+/g, '')?.toLowerCase();
	}

	private initForm(): void {
		if (!!this.toothClickedOn.ImplantTypeID) {
			const { scanBodyManufacturer, implantManufacturer, implantTypeScanBody } = this.implantService.getSelectedValuesByImplantId(
				this.toothClickedOn.ImplantTypeID
			);

			this.implantManufacturerOptions = this.implantService.filterImplantManufacturers(scanBodyManufacturer);
			this.implantTypeScanBodyOptions = this.implantService.filterImplants(scanBodyManufacturer, implantManufacturer);

			this.manufactureForm.patchValue({ scanBodyManufacturer, implantManufacturer, implantTypeScanBody }, { emitEvent: false });
		} else {
			this.implantManufacturerOptions = [];
			this.implantTypeScanBodyOptions = [];
			this.manufactureForm.patchValue(
				{ scanBodyManufacturer: null, implantManufacturer: null, implantTypeScanBody: null },
				{ emitEvent: false }
			);
		}
	}

	private initControlSubscriptions(): void {
		this.scanBodyManufacturerControl.valueChanges
			.pipe(
				tap(scanBodyMfg => {
					this.implantManufacturerOptions = this.implantService.filterImplantManufacturers(scanBodyMfg);
					const implantMfg = this.implantManufacturerControl.value;

					if (this.implantManufacturerOptions?.includes(implantMfg)) {
						this.implantTypeScanBodyOptions = this.implantService.filterImplants(scanBodyMfg, implantMfg);

						if (!this.implantTypeScanBodyOptions?.includes(this.implantTypeScanBodyControl.value)) {
							this.implantTypeScanBodyControl.patchValue(null);
						}
					} else {
						this.implantManufacturerControl.patchValue(null);
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.implantManufacturerControl.valueChanges
			.pipe(
				tap(implantMfg => {
					this.implantTypeScanBodyOptions = this.implantService.filterImplants(
						this.scanBodyManufacturerControl.value,
						implantMfg
					);

					if (!this.implantTypeScanBodyOptions?.includes(this.implantTypeScanBodyControl.value)) {
						this.implantTypeScanBodyControl.patchValue(null);
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.manufactureForm.valueChanges
			.pipe(
				tap(() => this.updateToothFromForm()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private updateToothFromForm(): void {
		this.toothEditorFacade.updateTooth({
			tooth: this.toothClickedOn,
			implantTypeId: this.implantTypeScanBodyControl.value?.Id ?? 0
		});
	}
}
