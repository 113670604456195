import {ProcedureMapEnum} from '@core/procedure-helpers/models/procedure-map.enum';
import {ProcedureRule} from '../../models/procedure-unit-type-material-rules-config';
import {UnitTypes} from '@modules/teeth-diagram/models/unit-type.enum';
import {allTeeth} from '../teeth-sets';

export const rulesForImplantPlanningGuideTooth: ProcedureRule = {
	Ids: [
		ProcedureMapEnum.ImplantPlanningGuideTooth,
		ProcedureMapEnum.ImplantPlanningGuideToothChairside,
		ProcedureMapEnum.ImplantPlanningGuideToothWithoutSendTo
	],
	TeethRules: [
		{
			Ids: allTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.ImplantPosition,
					},
					{
						Id: UnitTypes.SupportingTooth,
					},
					{
						Id: UnitTypes.Regular
					},
				]
			}
		},
	]
};