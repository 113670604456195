import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Awareness, AwarenessPopUpActions } from '@shared/models/enums/awareness.enum';
import { AwarenessQuery } from '@modules/awareness/awareness.query';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogButton } from '@modules/awareness/components/awareness-popup-layout/awareness-popup-layout.component';

@Component({
	selector: 'rx-awareness-popup',
	templateUrl: './awareness-popup.component.html',
	styleUrls: ['./awareness-popup.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwarenessPopupComponent {
	learningCenterLink$ = this.awarenessQuery.linkToTheLearningCenter$;
	showLearningCenterPart$ = this.awarenessQuery.showLearningCenterPart$;

	awarenessEnum = Awareness;

	buttonsBeforeLmr: DialogButton[] = [
		{ matDialogClose: AwarenessPopUpActions.RemindLater, term: 'Awareness.PopupRemindMeBeforeLmr' },
		{ matDialogClose: AwarenessPopUpActions.Confirm, term: 'Awareness.PopupConfirmBeforeLmr', confirm: true }
	];

	buttonsBeforeGA: DialogButton[] = [
		{ matDialogClose: AwarenessPopUpActions.Confirm, term: 'Awareness.PopupConfirmBeforeGA', confirm: true }
	];

	buttonsAfterLmrOrGA: DialogButton[] = [
		{ matDialogClose: AwarenessPopUpActions.Confirm, term: 'Awareness.PopupConfirmAfterLmr', confirm: true }
	];

	constructor(private awarenessQuery: AwarenessQuery, @Inject(MAT_DIALOG_DATA) public awareness: Awareness) {}
}
