<div class="rx-awareness-banner-container mt-4" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
	<ng-content></ng-content>
	<button mat-button (click)="openPopup()" class="rx-awareness-banner-trigger">{{ 'Awareness.BannerTitleOpenQR' | translate }}</button>
</div>

<ng-template #miniPopup>
	<div class="rx-awareness-mini-popup-container">
		<div fxLayout="row" fxLayoutAlign="end stretch">
			<button mat-icon-button mat-dialog-close>
				<img class="rx-awareness-mini-popup-cross" [src]="'assets/icons/cross.svg' | staticFilesEndpoint" alt="close"/>
			</button>
		</div>
		<div class="rx-awareness-mini-popup-content" fxLayout="column" fxLayoutAlign="center center">
			<span>{{ 'Awareness.BannerPopupQR' | translate }}</span>
			<rx-awareness-qr-code class="rx-awareness-mini-popup-qr" width="256" height="256"></rx-awareness-qr-code>
			<div *ngIf="showLearningCenterPart$ | async">
				<span>{{ 'Awareness.PopupLearningCenterOrPart' | translate | titlecase }} {{'Awareness.PopupLearningCenterVisitPart' | translate | lowercase }} </span>
				<a cdkFocusInitial [href]="linkToTheLearningCenter$ | async" target="_blank" rel="noopener noreferrer">{{ 'Awareness.PopupLearningCenter' | translate | lowercase }}</a>
			</div>
		</div>
	</div>
</ng-template>
