import {ProcedureMapEnum} from '@core/procedure-helpers/models/procedure-map.enum';
import {ProcedureRule} from '../../models/procedure-unit-type-material-rules-config';
import {UnitTypes} from '@modules/teeth-diagram/models/unit-type.enum';
import {allTeeth} from '../teeth-sets';

export const rulesForImplantPlanning: ProcedureRule = {
	Ids: [
		ProcedureMapEnum.ImplantPlanningDefault,
		ProcedureMapEnum.ImplantPlanningDefaultChairside,

		ProcedureMapEnum.ImplantPlanningGuideTissue,
		ProcedureMapEnum.ImplantPlanningGuideTissueChairside,
		ProcedureMapEnum.ImplantPlanningGuideTissueWithoutSendTo,

		ProcedureMapEnum.ImplantPlanningGuideBone,
		ProcedureMapEnum.ImplantPlanningGuideBoneChairside,
		ProcedureMapEnum.ImplantPlanningGuideBoneWithoutSendTo,

		ProcedureMapEnum.ImplantPlanningNoGuide,
		ProcedureMapEnum.ImplantPlanningNoGuideChairside,
		ProcedureMapEnum.ImplantPlanningNoGuideWithoutSendTo
	],
	TeethRules: [
		{
			Ids: allTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.ImplantPosition,
					},
					{
						Id: UnitTypes.Regular
					},
				]
			}
		},
	]
};