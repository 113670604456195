import { OnDestroy } from '@angular/core';
import { Timber } from '@itero/timber';
import { tap, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IRemoteConfig } from '@itero/timber/interfaces/config.remote';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ILogParameters } from '@itero/timber/interfaces/logParameters';
import { TimberMessageTypes } from '@shared/models/enums/enums';
import { LoggingSettings } from '@shared/models/environment-params';
import { AppenderType, LogLevel } from '@itero/timber/enums';
import { HostPlatformService } from '@shared/services/host-platform.service';

export abstract class BaseTimber extends BaseDestroyableComponent implements OnDestroy {
	protected timber: Timber;

	constructor(protected shellQuery: ShellQuery, protected hostPlatformService: HostPlatformService) {
		super();
	}

	static getTimberConfig(loggingSetting: LoggingSettings): IRemoteConfig {
		if (!loggingSetting) {
			return null;
		}
		const { url, appId, requiredFields, level, appenderTypes, eventType } = loggingSetting;
		return {
			url,
			appId,
			requiredFields,
			minLogLevel: LogLevel[level] ?? LogLevel.Off,
			appenderTypes: appenderTypes.filter(x => Object.values(AppenderType).includes(x)).map(x => AppenderType[x]),
			eventType
		} as IRemoteConfig;
	}

	protected createLogger(loggingSettings: LoggingSettings): void {
		this.timber = new Timber(BaseTimber.getTimberConfig(loggingSettings));
	}

	protected updateConfigWhenUserChanges$(): Observable<number> {
		return this.shellQuery.contactId$.pipe(
			takeUntil(this.componentAlive$),
			tap(contactId => this.timber.setConfig({ userId: contactId }))
		);
	}

	protected updateConfigWhenCompanyChanges$(): Observable<number> {
		return this.shellQuery.companyId$.pipe(
			takeUntil(this.componentAlive$),
			tap(companyId => this.timber.setConfig({ companyId }))
		);
	}

	protected logByType(message: string, messageType: TimberMessageTypes, parameters?: ILogParameters): void {
		if (this.hostPlatformService.isScanner) {
			const scannerMessage = `RxLog: ${message}, Parameters: ${JSON.stringify(parameters)}`;
			/* eslint-disable no-console */
			console.log(scannerMessage);
		}
		this.timber[messageType](message, parameters);
	}
}
