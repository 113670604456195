import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { getNewTooth, Tooth } from '@modules/teeth-diagram/models/tooth';
import { ToothEditorFacade } from '@modules/tooth-editor/tooth-editor.facade';
import { Observable } from 'rxjs';
import { IdName } from '@shared/models/id-name';
import { map, takeUntil, tap } from 'rxjs/operators';
import { getSortedListByName } from '@shared/services/sort-list-by-name-util';
import { FormBuilder } from '@angular/forms';
import { BaseDestroyableDirective } from '@shared/base-classes/base-destroyable';
import { ImplantService } from '@modules/tooth-editor/services/implant.service';

@Component({
	selector: 'rx-implant-position',
	templateUrl: './implant-position.component.html',
	styleUrls: ['./implant-position.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImplantPositionComponent extends BaseDestroyableDirective implements OnChanges, OnInit {
	@Input() toothClickedOn: Tooth;
	@Input() isReadOnly: boolean;

	constructor(private toothEditorFacade: ToothEditorFacade, private fb: FormBuilder, private implantService: ImplantService) {
		super();
	}

	implantManufacturerControl = this.fb.control(null);

	implantManufacturerOptions$: Observable<IdName[]> = this.implantService.activeImplantManufacturerOptions$.pipe(
		map(getSortedListByName)
	);

	ngOnChanges({ toothClickedOn }: SimpleChanges): void {
		if (toothClickedOn.currentValue !== toothClickedOn.previousValue) {
			this.updateForm(toothClickedOn.currentValue);
		}
	}

	ngOnInit() {
		this.implantManufacturerControl.valueChanges
			.pipe(
				tap((implantManufacturer: IdName) => {
					this.updateStore(implantManufacturer?.Id);
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		if (this.toothClickedOn && !this.toothClickedOn.SpecificationId) {
			this.toothClickedOn.SpecificationId = getNewTooth().SpecificationId;
		}
		this.toothEditorFacade.updateTooth({ tooth: this.toothClickedOn });
	}

	private updateForm(toothClickedOn: Tooth): void {
		if (!!toothClickedOn.ImplantTypeID) {
			const { implantManufacturer } = this.implantService.getSelectedValuesByImplantId(toothClickedOn.ImplantTypeID);

			this.implantManufacturerControl.patchValue(implantManufacturer, { emitEvent: false });
		}
	}

	private updateStore(manufacturerId: number): void {
		this.toothEditorFacade.updateTooth({
			tooth: this.toothClickedOn,
			implantTypeId: this.implantService.getFirstActiveImplantIdByManufacturerId(manufacturerId)
		});
	}
}
