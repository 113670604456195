import { Injectable } from '@angular/core';
import { LoggerService } from '@core/services/logger/logger.service';
import { emptyGuid } from '@shared/models/consts';
import { PatientModelDto } from '@shared/models/rx-models/interfaces/patient-model-dto';
import { PopupService } from '@shared/services/popup.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { objectKeysToLowerCase } from '@shared/utils/object-keys-tolower-util';
import { Observable, fromEvent, Subject, BehaviorSubject } from 'rxjs';
import { audit, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PatientAppEvent } from '../models/patient-app-event';
import { patientAppEventTypes } from '../models/patient-app-events.enum';
import { PatientStore } from '../state/patient-store';
import { PatientAppUrlBuilderService } from './patient-app-url-builder.service';
import { PatientAppPopUpComponent } from '@modules/patient/components/patient-app-popup/patient-app-popup.component';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ShellStore } from '@shared/store/shell/shell-store';
import { PopUpActions } from '@shared/models/enums/popup-modal-actions.enum';
import { HostPlatformService } from '@shared/services/host-platform.service';

export enum PatientPopupCommands {
	EditPatient = 1,
	SearchPatient = 2
}

@Injectable({
	providedIn: 'root'
})
export class PatientAppIframeCommunicationService {
	private readonly moduleName = 'PatientAppIframeCommunicationService';

	private patientPopUpClosed$: Subject<boolean> = new Subject<boolean>();

	patientPopupCommandEmitter$: Subject<PatientPopupCommands> = new Subject<PatientPopupCommands>();
	patientPopupCommandHandler$ = this.patientPopupCommandEmitter$.pipe(
		switchMap((command: PatientPopupCommands) => this.handlePatientPopupCommand(command)),
		audit(_ => this.patientPopUpClosed$),
		tap(dialogRef => dialogRef.close())
	);

	private patientSavedEvent$: Subject<boolean> = new Subject<boolean>();

	private _patientAppReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isPatientAppReady$: Observable<boolean> = this._patientAppReady$.asObservable();

	private _patientAppError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isPatientAppError$: Observable<boolean> = this._patientAppError$.asObservable();

	private patientConflictPopupSubject = new Subject<PopUpActions>();
	patientConflictPopup$ = this.patientConflictPopupSubject.pipe();

	isListeningToPatientSave = false;
	private iFrameMessages$: Observable<MessageEvent> = fromEvent<MessageEvent>(window, 'message');
	private inlinePatientAppIframeId = 'inline-patient-app';

	constructor(
		private logger: LoggerService,
		private patientStore: PatientStore,
		private shellStore: ShellStore,
		private shellQuery: ShellQuery,
		private patientAppUrlBuilderService: PatientAppUrlBuilderService,
		private popupService: PopupService,
		private hostPlatformService: HostPlatformService
	) {}

	registerToPatientAppEvents(): Observable<any> {
		this.logger.info('registerToPatientApp', { module: this.moduleName });

		this.patientStore.update({ isPatientAppConfigured: true });

		return this.iFrameMessages$.pipe(
			withLatestFrom(this.shellQuery.patientAppUrl$),
			filter(([event, appSettings]) => this.isEventSourceIsPatientApp(event, appSettings)),
			tap(([event]) => this.handleEventFromPatientApp(event))
		);
	}

	savePatient(): Observable<boolean> {
		this.isListeningToPatientSave = true;
		this.postMessageForCreatePatient();
		return this.patientSavedEvent$.pipe(
			tap(() => {
				this.isListeningToPatientSave = false;
			})
		);
	}

	postMessageForCreatePatient(): void {
		this.postMessageToPatientApp(patientAppEventTypes.patientAppCreatePatient);
	}

	postMessageForClearPatient(): void {
		this.postMessageToPatientApp(patientAppEventTypes.patientAppClearPatient);
	}

	setPatientAppReadyState(isReady: boolean): void {
		this._patientAppReady$.next(isReady);
		this._patientAppError$.next(false);
	}

	getPatientPopupConfig(): Partial<MatDialogConfig> {
		const isHostPlatformScanner = this.hostPlatformService.isScanner;

		if (isHostPlatformScanner) {
			return { position: { top: '15px' } };
		}

		return {};
	}

	patientAppPopupClose(): void {
		this.popupService.closePopUp();
	}

	private handleEventFromPatientApp(event: MessageEvent): void {
		if (!event.data || !event.data.eventId) {
			this.logger.error(`No data or eventId from PatientApp to RxApp: ${JSON.stringify(event)}`, {
				module: this.moduleName
			});
			return;
		}
		const isPatientDataEmptyFromPopup = this.popupService.isPopupOpenById('patient-app-popup') && !event.data?.data;
		if (isPatientDataEmptyFromPopup) {
			this.logger.info('No patient data from popup - no update made.', {
				module: this.moduleName
			});
			this.patientPopUpClosed$.next(true);
			return;
		}
		const { eventId, data: eventData, status } = event.data as PatientAppEvent;
		this.logger.info(`Event from PatientApp to RxApp: eventId ${eventId}, eventName ${patientAppEventTypes[eventId]}`, {
			module: this.moduleName
		});
		switch (eventId) {
			case patientAppEventTypes.patientAppReady:
				if (eventData === 'patientAppReady') {
					this._patientAppReady$.next(true);
				}
				break;
			case patientAppEventTypes.patientAppAddPatient:
				this.patientPopUpClosed$.next(true);
				break;
			case patientAppEventTypes.patientAppSelectedPatient:
			case patientAppEventTypes.patientAppUpdateHostPatient:
				this.onPatientUpdated(eventData);
				this.patientPopUpClosed$.next(true);
				break;
			case patientAppEventTypes.patientAppSelectedPatientConflict:
				// TODO
				break;
			case patientAppEventTypes.patientAppRedirect:
				// TODO
				break;
			case patientAppEventTypes.patientAppDialog:
				if (eventData === 'patientAppDialogOpen') {
					this.shellStore.update({ isPatientAppDialogOpen: status === 'open' });
				}
				break;
			case patientAppEventTypes.patientAppSearchPatient:
				this.patientPopupCommandEmitter$.next(PatientPopupCommands.SearchPatient);
				break;
			case patientAppEventTypes.patientAppPatientInConflict:
				const isPatientInConflict = eventData;
				this.patientStore.update({ isPatientInConflict: !!isPatientInConflict });
				this.pushAfterSaveEvent(false);
				break;
			case patientAppEventTypes.patientAppError:
				this._patientAppError$.next(true);
				break;
			case patientAppEventTypes.patientAppConfirmConflictPopup:
				this.patientConflictPopupSubject.next(PopUpActions.Ok);
				break;
			case patientAppEventTypes.patientAppCancelConflictPopup:
				this.patientConflictPopupSubject.next(PopUpActions.Cancel);
				break;
			default:
				this.logger.error(`Unknown event from PatientApp to RxApp: ${JSON.stringify(event)}`, {
					module: this.moduleName
				});
		}
	}

	private postMessageToPatientApp(eventId: patientAppEventTypes, data?: any): void {
		const message = { eventId, data };
		const iframe: HTMLIFrameElement = document.getElementById(this.inlinePatientAppIframeId) as HTMLIFrameElement;
		iframe?.contentWindow?.postMessage(message, '*');
	}

	private isEventSourceIsPatientApp(event: MessageEvent, patientAppUrl: string): boolean {
		return patientAppUrl?.includes(event.origin);
	}

	private onPatientUpdated(data: PatientModelDto): void {
		if (data === null) {
			this.patientStore.update({ patient: null });
			return;
		}
		const patientToUpdate = this.mapPatientAppModelToRxModel(data);
		this.patientStore.updatePatient(patientToUpdate, this.shellQuery.dateFormat, this.shellQuery.regulatoryDOBMask);
		this.pushAfterSaveEvent(true);
	}

	private pushAfterSaveEvent(isPatientSaved: boolean): void {
		if (!this.isListeningToPatientSave) {
			return;
		}
		this.patientSavedEvent$.next(isPatientSaved);
		this.isListeningToPatientSave = false;
	}

	private mapPatientAppModelToRxModel(data: PatientModelDto): PatientModelDto {
		const patientToLower = objectKeysToLowerCase(data);
		return {
			Id: patientToLower.id,
			UID: patientToLower.uid || emptyGuid,
			FirstName: patientToLower.firstname,
			LastName: patientToLower.lastname,
			ChartNumber: patientToLower.chartnumber,
			ZipCode: patientToLower.zipcode,
			DateOfBirth: patientToLower.dateofbirth?.toString(),
			MI: patientToLower.mi,
			Gender: patientToLower.gender,
			Type: patientToLower.type || 1,
			AlignPatientId: patientToLower.alignpatientid,
			FullName: `${patientToLower.firstname} ${patientToLower.lastname}`
		};
	}

	private handlePatientPopupCommand(command: PatientPopupCommands): Observable<MatDialogRef<PatientAppPopUpComponent>> {
		switch (command) {
			case PatientPopupCommands.EditPatient:
				return this.openEditPopup();
			case PatientPopupCommands.SearchPatient:
				return this.openSearchPopup();
		}
	}

	private openEditPopup(): Observable<MatDialogRef<PatientAppPopUpComponent>> {
		const config: Partial<MatDialogConfig> = this.getPatientPopupConfig();

		return this.patientAppUrlBuilderService
			.getEditPatientAppUrl()
			.pipe(map(url => this.popupService.openPatientAppPopUp({ editPatientAppUrl: url }, config)));
	}

	private openSearchPopup(): Observable<MatDialogRef<PatientAppPopUpComponent>> {
		const config: Partial<MatDialogConfig> = this.getPatientPopupConfig();

		return this.patientAppUrlBuilderService
			.getSearchPatientAppUrl()
			.pipe(map(url => this.popupService.openPatientAppPopUp({ searchPatientAppUrl: url }, config)));
	}
}
