import { NgModule } from '@angular/core';

// Angular Material Components
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatCardModule } from '@angular/material/card';
// import { MatStepperModule } from '@angular/material/stepper';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatSortModule } from '@angular/material/sort';
// import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
	declarations: [],
	imports: [
		MatButtonModule,
		MatToolbarModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatGridListModule,
		MatIconModule,
		MatCheckboxModule,
		MomentDateModule,
		MatMomentDateModule,
		MatMenuModule,
		MatListModule,
		MatDialogModule,
		ClipboardModule,
		MatSnackBarModule,
		MatTableModule,
		MatTooltipModule,
		// MatAutocompleteModule,
		MatRadioModule
		// MatSliderModule,
		// MatSidenavModule,
		// MatCardModule,
		// MatStepperModule,
		// MatTabsModule,
		// MatExpansionModule,
		// MatButtonToggleModule,
		// MatChipsModule,
		// MatProgressSpinnerModule,
		// MatProgressBarModule,
		// MatSortModule,
		// MatPaginatorModule
	],
	exports: [
		MatButtonModule,
		MatToolbarModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatGridListModule,
		MatIconModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatMenuModule,
		MatListModule,
		MatDialogModule,
		MatExpansionModule,
		ClipboardModule,
		MatSnackBarModule,
		MatTableModule,
		MatTooltipModule,
		MomentDateModule,
		MatMomentDateModule,
		// MatAutocompleteModule,
		MatRadioModule
		// MatSliderModule,
		// MatSidenavModule,
		// MatCardModule,
		// MatStepperModule,
		// MatTabsModule,
		// MatButtonToggleModule,
		// MatChipsModule,
		// MatProgressSpinnerModule,
		// MatProgressBarModule,
		// MatSortModule,
		// MatPaginatorModule
	]
})
export class MaterialModule {}
