<mat-label
    class="pontic-design-label"
    [ngClass]="{'read-only-label': isReadonly, 'disabled-label': control?.disabled}"
    *ngIf="showLabel"
    >{{ 'Pontic Design' | translate }}
</mat-label>
<mat-radio-group
    [id]="'pontic-designs-radio-group'"
    [formControl]="control"
    fxLayout="row"
    fxLayoutAlign="space-between end"
    aria-labelledby="pontic-designs"
    [class.read-only]="isReadonly"
    color="primary"
    class="pontic-selector"
>
    <div
            [id]="'pontic-design-wrapper-' + option?.Id"
            [matTooltip]="option?.Name"
            [matTooltipPosition]="'below'"
            class="pontic-container"
            *ngFor="let option of options | mapper : addDefaultOptionForPontics"
    >
        <mat-label>
            <mat-radio-button [value]="option" class="pontic-selector_button" [class.read-only]="isReadonly">
                <img class="custom-selector"
                     [src]="null | mapper : getSrcForRadioButtonImg.bind(this)"
                     *ngIf="isReadonly"
                     alt="checked"
                >
                <a class="default-option-label" *ngIf="!option">{{ 'ToothEditor.NA' | translate }}</a>
                <img *ngIf="option" [src]="option | mapper : getSrcForPonticDesignImage.bind(this)" [alt]="option?.Name" />
            </mat-radio-button>
        </mat-label>
    </div>
</mat-radio-group>