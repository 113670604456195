import { Query } from '@datorama/akita';
import { LocalSettingsState, LocalSettingsStore } from '@shared/store/localSettings/local-settings-store';
import { LocalSettings } from '@shared/models/local-settings';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalSettingsQuery extends Query<LocalSettingsState> {
	get userSettings(): Record<number, LocalSettings> {
		return this.getValue().userSettings;
	}

	constructor(store: LocalSettingsStore) {
		super(store);
	}
}
