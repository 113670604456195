import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, combineLatest, merge, Observable, of } from 'rxjs';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { OrderQuery } from '@modules/order/state/order-query';
import { distinctUntilChanged, map, tap, switchMap, delay } from 'rxjs/operators';
import { LoggerService } from '@core/services/logger/logger.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { VersionsService } from '@shared/services/versions.service';
import { LimitedFeatures } from '@shared/models/limited-features';
import { ShellStore } from '@shared/store/shell/shell-store';
import { SdkInputs } from '@shared/models/sdk-inputs';
import { ProductTypes, SoftwareOptions, FeatureToggleSettings } from '@shared/models/enums/enums';
import { CaseTypeEnum } from '@modules/order/models/case-type.enum';
import { RxForDoctorStore } from './state/rx-for-doctor-store';
import { PdfCreationService } from '@shared/services/pdf-creation.service';
import { RxForDoctorQuery } from './state/rx-for-doctor-query';
import { PatientAppIframeCommunicationService } from '@modules/patient/services/patient-app-iframe-communication.service';
import { SpinnerService } from '@core/services/spinner.service';
import { patientSectionModeEnum } from '@modules/patient/models/patient-section-visibility.enum';
import { PatientQuery } from '@modules/patient/state/patient-query';
import debounce from 'lodash.debounce';
import { DentureDetailsUtils } from '@modules/denture-details/utils/denture-details-utils';
import { combineQueries } from '@datorama/akita';
import { AwarenessQuery } from '@modules/awareness/awareness.query';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable()
export class RxForDoctorFacade {
	onSDKInputsChange$: Observable<SdkInputs> = this.shellQuery.onSDKInputsChange$;
	rxConfiguration$: Observable<RxConfiguration> = this.shellQuery.rxConfiguration$;
	isChairSideMillingAvailableForDownload$ = new BehaviorSubject<boolean>(false);
	companyCountryCode$: Observable<string> = this.shellQuery.companyCountryCode$;
	isReadOnly$: Observable<boolean> = this.shellQuery.isReadOnly$;
	contactId$: Observable<number> = this.shellQuery.contactId$;
	isReferralWorkflowPractice$: Observable<boolean> = this.rxForDoctorQuery.isReferralWorkflowPractice$;
	isRxValidForSave$: Observable<boolean> = this.shellQuery.isRxValidForSave$;
	isRxSent$: Observable<boolean> = this.shellQuery.isRxSent$;
	isTeethDiagramAreaVisible$: Observable<boolean> = combineQueries([
		this.shellQuery.isProcedureFlow$,
		this.orderQuery.caseType$,
		this.orderQuery.procedureMap$
	]).pipe(
		map(([isProcedureFlow, caseTypeSelected, procedureMap]) => {
			if (isProcedureFlow && procedureMap) {
				return procedureMap.ToothDiagramIsEnabled;
			}
			return [
				CaseTypeEnum.Restorative,
				CaseTypeEnum.ChairSideMilling,
				CaseTypeEnum.ChairSideMillingGlidewellIo,
				CaseTypeEnum.ChairSideMillingCerec
			].includes(caseTypeSelected?.Id);
		})
	);

	isMultiBiteVisible$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(map(x => x?.MultiBiteIsEnabled));

	isScanOptionsVisible$: Observable<boolean> = combineQueries([
		this.shellQuery.isScanOptionsVisible$,
		this.orderQuery.procedureMap$
	]).pipe(
		map(
			([isScanOptionsVisible, procedureMap]) =>
				isScanOptionsVisible ||
				!!(procedureMap?.MultiBiteIsEnabled || procedureMap?.DentureCopyScanIsEnabled || procedureMap?.PreTreatmentScanIsEnabled)
		)
	);

	isProcedureFlow$: Observable<boolean> = this.shellQuery.isProcedureFlow$;

	isDentureDetailsVisible$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(
		map(procedureMap => DentureDetailsUtils.isDentureDetailsEnabled(procedureMap?.ProcedureId))
	);

	isPatientAppReady$: Observable<boolean> = this.patientQuery.patientSectionMode$.pipe(
		switchMap(mode =>
			mode === patientSectionModeEnum.InlinePatientApp ? this.patientAppIframeCommunicationService.isPatientAppReady$ : of(true)
		)
	);

	isPatientAppError$: Observable<boolean> = this.patientAppIframeCommunicationService.isPatientAppError$;

	isPatientReadyAndLoaded$: Observable<boolean> = this.setPatientAppLoader().pipe(isPatientLoaded => isPatientLoaded);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	debouncedHandleResize = debounce<() => void>(this.normalizeScroll.bind(this), 500);

	readonly facadeName = 'RxForDoctorFacade';

	get noticeComponentConfig(): { companyCountryCode: string; noticeTranslationPath: string }[] {
		return [{ companyCountryCode: 'RU', noticeTranslationPath: 'NoticeForRussianCompany' }];
	}

	isReadOnlyBannerVisible$ = combineLatest([this.isReadOnly$, this.isRxSent$]).pipe(
		map(([isReadOnly, isRxSent]) => isReadOnly && isRxSent)
	);

	showLmrAwareness$ = this.awarenessQuery.showLmrAwareness$;
	showGaAwareness$ = this.awarenessQuery.showGaAwareness$;

	constructor(
		private patientQuery: PatientQuery,
		private orderQuery: OrderQuery,
		private shellQuery: ShellQuery,
		private shellStore: ShellStore,
		private rxForDoctorQuery: RxForDoctorQuery,
		private rxForDoctorStore: RxForDoctorStore,
		private logger: LoggerService,
		private softwareOptionsService: SoftwareOptionsService,
		private versionsService: VersionsService,
		private pdfCreationService: PdfCreationService,
		private patientAppIframeCommunicationService: PatientAppIframeCommunicationService,
		private spinnerService: SpinnerService,
		private awarenessQuery: AwarenessQuery,
		private hostPlatformService: HostPlatformService
	) {}

	initStoreValues(): Observable<any> {
		return merge(
			this.subscribeOnScannerIdChanged(),
			this.setIsNiriCaptureVisibility(),
			this.setSleeveConfirmationVisibility(),
			this.setPreTreatmentVisibilityForV0(),
			this.setIsChairSideMillingAvailableForDownload(),
			this.setIsReferralWorflowPractice()
		);
	}

	requestPdfCreation() {
		this.pdfCreationService.pdfRequested.next(true);
	}

	setPatientAppReadyState(isReady: boolean) {
		this.patientAppIframeCommunicationService.setPatientAppReadyState(isReady);
	}

	isDownloadPdfButtonVisible(): Observable<boolean> {
		return combineLatest([this.isReferralWorkflowPractice$, this.shellQuery.isRxSent$]).pipe(
			map(([isReferralWorkflowPractice, isRxSent]) => {
				return !!(isReferralWorkflowPractice && !isRxSent);
			})
		);
	}

	rxComponentsNormalizeScrollPosition() {
		window.addEventListener('resize', this.debouncedHandleResize);
	}

	private normalizeScroll(): void {
		document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
	}

	private setIsNiriCaptureVisibility() {
		return combineLatest([
			this.hostPlatformService.isScanner$,
			this.softwareOptionsService.hasScannerSoftwareOption$(SoftwareOptions.ShouldEnableEvx),
			this.shellQuery.productType$
		]).pipe(
			map(([isHostPlatformScanner, hasShouldEnableEvxSwOption, productType]) => {
				if (isHostPlatformScanner) {
					return productType === ProductTypes.evx && hasShouldEnableEvxSwOption;
				}
				return hasShouldEnableEvxSwOption;
			}),
			tap(isNiriCaptureVisible => this.shellStore.update({ isNiriCaptureVisible }))
		);
	}

	private setSleeveConfirmationVisibility() {
		return combineLatest([
			this.hostPlatformService.isScanner$,
			this.shellQuery.productType$,
			this.shellQuery.isRxSent$,
			this.shellQuery.clientVersion$,
			this.shellQuery.packageVersion$,
			this.softwareOptionsService.hasScannerSoftwareOption$(SoftwareOptions.ShouldEnableEvx),
			this.shellQuery.isSleeveConfirmed$
		]).pipe(
			map(
				([
					isHostPlatformScanner,
					productType,
					isRxSent,
					clientVersion,
					packageVersion,
					hasShouldEnableEvxSwOption,
					isSleeveConfirmed
				]: [boolean, string, boolean, string, string, boolean, boolean]) => {
					if (isHostPlatformScanner) {
						if (isRxSent && isSleeveConfirmed) {
							return true;
						}
						switch (productType) {
							case ProductTypes.evx:
								return (
									hasShouldEnableEvxSwOption &&
									this.versionsService.isFeatureAvailableForScanner(
										LimitedFeatures.SleeveConfirmation,
										clientVersion,
										packageVersion
									)
								);
							case ProductTypes.ng:
								return true;
							default:
								return false;
						}
					}
					return false;
				}
			),
			tap(isSleeveConfirmationVisible => this.shellStore.update({ isSleeveConfirmationVisible }))
		);
	}

	private setPreTreatmentVisibilityForV0() {
		return combineLatest([
			this.hostPlatformService.isScanner$,
			this.orderQuery.caseType$,
			this.shellQuery.isRxSent$,
			this.shellQuery.prePrepScan$
		]).pipe(
			map(([isHostPlatformScanner, caseType, isRxSent, isPreTreatment]) => {
				if (isHostPlatformScanner) {
					return (
						(isRxSent && isPreTreatment) ||
						[CaseTypeEnum.Restorative, CaseTypeEnum.ChairSideMilling, CaseTypeEnum.ChairSideMillingGlidewellIo].includes(
							caseType?.Id
						)
					);
				}
				return false;
			}),
			tap(isPreTreatmentVisibleForV0 => this.shellStore.update({ isPreTreatmentVisibleForV0 }))
		);
	}

	private setIsReferralWorflowPractice() {
		return combineLatest([
			this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.ReferralWorkflowPractice),
			this.shellQuery.featureToggles$
		]).pipe(
			map(([isReferralWorkflowPractice, featureToggles]) => {
				const isReferralWorkflowActive = featureToggles?.find(
					toggle => toggle.id === FeatureToggleSettings.ReferralWorkflow
				)?.isActive;
				return isReferralWorkflowActive && isReferralWorkflowPractice;
			}),
			tap(isReferralWorkflowPractice => {
				this.rxForDoctorStore.update({ isReferralWorkflowPractice });
			})
		);
	}

	private subscribeOnScannerIdChanged() {
		return this.onSDKInputsChange$.pipe(
			distinctUntilChanged((prev, curr) => prev.scannerId === curr.scannerId),
			tap(sdkInputs => {
				const { scannerId } = sdkInputs;
				this.shellStore.update({ scannerId });
				this.logger.info(`${this.facadeName}:setCurrentScannerId: ${scannerId}`, { module: this.facadeName });
			})
		);
	}

	private setIsChairSideMillingAvailableForDownload() {
		return combineLatest([
			this.softwareOptionsService.hasScannerSoftwareOptions$([
				SoftwareOptions.ChairSideMillingE4D,
				SoftwareOptions.ChairSideMillingOther,
				SoftwareOptions.ChairSideMillingGlidewell
			]),
			this.orderQuery.isCurrentCaseType$(CaseTypeEnum.ChairSideMilling)
		]).pipe(
			map(([hasSuitableSoftewareOptions, isChairSideMillingCaseType]) => isChairSideMillingCaseType && hasSuitableSoftewareOptions),
			tap(result => this.isChairSideMillingAvailableForDownload$.next(result))
		);
	}

	private setPatientAppLoader() {
		return merge(
			defer(() => this.spinnerService.pin()),
			combineLatest([
				this.isPatientAppError$,
				this.isPatientAppReady$,
				this.shellQuery.isRxPending$,
				this.shellQuery.isLoading$
			]).pipe(
				map(([isError, isReady, isRxPending, isLoading]) => {
					const isLoaded = (isError || isReady) && !isRxPending && !isLoading;

					if (isLoaded) {
						this.spinnerService.unpin();
						return true;
					}
					return false;
				}),
				delay(500)
			)
		);
	}
}
