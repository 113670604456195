import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@core/services/logger/logger.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	public readonly name = 'HttpErrorInterceptor';

	constructor(private injector: Injector) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				const logger = this.injector.get(LoggerService);
				let extendedParameters: { [key: string]: string } = {
					url: error?.url,
					statusCode: error?.status.toString()
				};

				const traceId = error?.headers?.get('x-trace');
				if (traceId) {
					extendedParameters = { ...extendedParameters, traceId };
				}

				const exceptionMessage = error.error?.ExceptionMessage;
				if (exceptionMessage) {
					extendedParameters = { ...extendedParameters, exceptionMessage };
				}

				logger.error(error.message, {
					module: this.name,
					extendedParameters
				});

				return throwError(error);
			})
		);
	}
}
