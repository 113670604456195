import { Component, OnDestroy, OnInit } from '@angular/core';
import { AwarenessService } from '@modules/awareness/services/awareness.service';
import { RxForDoctorFacade } from '@modules/rx-for-doctor/rx-for-doctor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { Observable } from 'rxjs';
import { filter, shareReplay, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'rx-for-doctor',
	templateUrl: './rx-for-doctor.component.html',
	styleUrls: ['./rx-for-doctor.component.scss'],
	providers: [RxForDoctorFacade]
})
export class RxForDoctorComponent extends BaseDestroyableComponent implements OnInit, OnDestroy {
	rxConfiguration$: Observable<RxConfiguration> = this.facade.rxConfiguration$;
	isChairSideMillingAvailableForDownload$: Observable<boolean> = this.facade.isChairSideMillingAvailableForDownload$;
	companyCountryCode$: Observable<string> = this.facade.companyCountryCode$;
	isScanOptionsVisible$: Observable<boolean> = this.facade.isScanOptionsVisible$;
	isTeethDiagramAreaVisible$: Observable<boolean> = this.facade.isTeethDiagramAreaVisible$;
	isPdfButtonVisible$: Observable<boolean> = this.facade.isDownloadPdfButtonVisible();
	isPdfButtonDisabled$: Observable<boolean> = this.facade.isRxValidForSave$.pipe(map(isRxValid => !isRxValid));
	isProcedureFlow$: Observable<boolean> = this.facade.isProcedureFlow$;
	isDentureDetailsVisible$: Observable<boolean> = this.facade.isDentureDetailsVisible$;
	isMultiBiteVisible$: Observable<boolean> = this.facade.isMultiBiteVisible$;

	noticeConfig: { companyCountryCode: string; noticeTranslationPath: string }[] = this.facade.noticeComponentConfig;
	isPatientAppReady$: Observable<boolean> = this.facade.isPatientAppReady$;
	isPatientReadyAndLoaded$: Observable<boolean> = this.facade.isPatientReadyAndLoaded$;
	isReadOnlyBannerVisible$ = this.facade.isReadOnlyBannerVisible$;
	showLmrAwareness$ = this.facade.showLmrAwareness$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
	showGaAwareness$ = this.facade.showGaAwareness$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

	constructor(private facade: RxForDoctorFacade, private awarenessService: AwarenessService) {
		super();
	}

	ngOnInit(): void {
		this.facade.contactId$
			.pipe(
				tap(() => {
					this.initRxForDoctor().subscribe();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.subscribeToIsPatientAppReady();
		this.showAwareness();
	}

	ngOnDestroy() {
		this.facade.setPatientAppReadyState(false);
		window.removeEventListener('resize', this.facade.debouncedHandleResize);
		super.ngOnDestroy();
	}

	subscribeToIsPatientAppReady() {
		this.isPatientAppReady$
			.pipe(
				tap(isReady => {
					if (isReady) {
						this.facade.rxComponentsNormalizeScrollPosition();
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	handlePdfButtonClick() {
		this.facade.requestPdfCreation();
	}

	private showAwareness() {
		this.isPatientReadyAndLoaded$
			.pipe(
				filter(isReady => !!isReady),
				switchMap(() => this.awarenessService.showAwareness()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private initRxForDoctor(): Observable<unknown> {
		return this.facade.initStoreValues();
	}
}
