import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { defaultDateFormat, defaultLanguageCode } from '@shared/models/consts';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Moment } from 'moment';

@Injectable({ providedIn: 'root' })
export class UserSettingsDateAdapter extends MomentDateAdapter {

	constructor(
		private shellQuery: ShellQuery
	) {
		super(shellQuery.languageCode ?? defaultLanguageCode);
	}

	format(date: Moment, displayFormat: string): string {
		const dateFormatAccordingToUserSettings = this.shellQuery.dateFormat ?? defaultDateFormat.toUpperCase();
		return date.format(dateFormatAccordingToUserSettings);
	}
}
