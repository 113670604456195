import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MaterialModule } from './material/material.module';
import { AlertComponent } from './components/alert/alert.component';
import { ToothComponent } from '@modules/teeth-diagram/components/tooth/tooth.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmationPopUpComponent } from './components/confirmation-pop-up/confirmation-pop-up.component';
import { SimpleSelectorComponent } from './components/simple-selector/simple-selector.component';
import { ObjectSelectorComponent } from './components/object-selector/object-selector.component';
import { RxBannerComponent } from './components/rx-banner/rx-banner.component';
import { MapperPipe } from './pipes/mapper.pipe';
import { IsRequiredDirective } from './directives/is-required.directive';
import { IsDisabledDirective } from './directives/is-disabled/is-disabled.directive';
import { StaticFilesEndpointPipe } from './pipes/static-files-endpoint.pipe';

@NgModule({
	declarations: [
		SpinnerComponent,
		AlertComponent,
		ToothComponent,
		ConfirmationPopUpComponent,
		SimpleSelectorComponent,
		ObjectSelectorComponent,
		RxBannerComponent,
		MapperPipe,
		IsRequiredDirective,
		IsDisabledDirective,
		StaticFilesEndpointPipe
	],
	imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, TranslateModule, HttpClientModule, FlexLayoutModule],
	exports: [
		SpinnerComponent,
		AlertComponent,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		HttpClientModule,
		ToothComponent,
		FlexLayoutModule,
		ConfirmationPopUpComponent,
		SimpleSelectorComponent,
		ObjectSelectorComponent,
		RxBannerComponent,
		MapperPipe,
		IsRequiredDirective,
		IsDisabledDirective,
		StaticFilesEndpointPipe
	],
	providers: []
})
export class SharedModule {}
