export enum ProcedureMapEnum {
	ImplantPlanningGuideTooth = 24,
	ImplantPlanningGuideTissue = 25,
	ImplantPlanningGuideBone = 26,
	ImplantPlanningGuideToothChairside = 27,
	ImplantPlanningGuideTissueChairside = 28,
	ImplantPlanningGuideTissueWithoutSendTo = 60,
	ImplantPlanningGuideBoneChairside = 29,
	ImplantPlanningGuideBoneWithoutSendTo = 61,
	ImplantPlanningGuideToothWithoutSendTo = 59,

	FixedRestorativeLabResto = 30,
	FixedRestorativeChairsideExocad = 31,
	FixedRestorativeGlidewellIo = 32,
	FixedRestorativeWithoutSendTo = 62,

	ImplantPlanningDefault = 44,
	ImplantPlanningDefaultChairside = 45,
	ImplantPlanningNoGuide = 46,
	ImplantPlanningNoGuideChairside = 47,
	ImplantPlanningNoGuideWithoutSendTo = 64,

	DentureRemovablePartialDentureFramework = 20,
	DentureRemovablePartialDentureFrameworkWithoutSendTo = 57,
	DentureRemovableImmediateDenture = 21,
	DentureRemovableImmediateDentureWithoutSendTo = 58,
	DentureRemovableFullDentureTissueBased = 22,
	DentureRemovableFullDentureImplantBased = 23,
	DentureRemovableFullDentureImplantBasedWithoutSendTo = 71,
	DentureRemovable = 48
}
