import { Injectable, OnDestroy } from '@angular/core';
import { BaseTimber } from '@shared/base-classes/base-timber';
import { TimberMessageTypes } from '@shared/models/enums/enums';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { LogParameters } from './interfaces/log.parameters';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable({ providedIn: 'root' })
export class LoggerService extends BaseTimber implements OnDestroy {
	constructor(
		private appSettingsService: AppSettingsService,
		protected shellQuery: ShellQuery,
		hostPlatformService: HostPlatformService
	) {
		super(shellQuery, hostPlatformService);
		this.createLogger(appSettingsService.loggingSetting);
		this.updateConfigWhenUserChanges$().subscribe();
		this.updateConfigWhenCompanyChanges$().subscribe();
	}

	trace(message: string, parameters: LogParameters): void {
		super.logByType(message, TimberMessageTypes.trace, parameters);
	}

	debug(message: string, parameters: LogParameters): void {
		super.logByType(message, TimberMessageTypes.debug, parameters);
	}

	info(message: string, parameters: LogParameters): void {
		super.logByType(message, TimberMessageTypes.info, parameters);
	}

	warn(message: string, parameters: LogParameters): void {
		super.logByType(message, TimberMessageTypes.warn, parameters);
	}

	error(message: string, parameters: LogParameters): void {
		super.logByType(message, TimberMessageTypes.error, parameters);
	}

	fatal(message: string, parameters: LogParameters): void {
		super.logByType(message, TimberMessageTypes.fatal, parameters);
	}

	logRXData(rx: RxModel, text: string, module: string): any {
		const rxForLog = {
			...rx,
			Teeth: undefined,
			Bridges: undefined,
			Patient: { UID: rx.Patient?.UID },
			Signature: undefined,
			OrderInformation: undefined,
			Notes: undefined,
			NotesArray: undefined,
			TechnicalNotes: undefined,
			AligntechNotes: undefined,
			AlignTechNotesArray: undefined
		};

		this.info(`${text}${JSON.stringify(rxForLog)}`, {
			module
		});

		return rxForLog;
	}

	protected updateConfigWhenUserChanges$(): Observable<number> {
		return super
			.updateConfigWhenUserChanges$()
			.pipe(tap(contactId => this.info(`User has changed, contactId: ${contactId}`, { module: 'LoggerService' })));
	}

	protected updateConfigWhenCompanyChanges$(): Observable<number> {
		return super
			.updateConfigWhenCompanyChanges$()
			.pipe(tap(companyId => this.info(`Company has changed, companyId: ${companyId}`, { module: 'LoggerService' })));
	}
}
