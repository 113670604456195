import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { AwarenessService } from '@modules/awareness/services/awareness.service';
import { Region } from '@shared/models/country-region-rule';
import { AwarenessQuery } from '@modules/awareness/awareness.query';

@Component({
	selector: 'rx-awareness-qr-code',
	templateUrl: './awareness-qr-code.component.html',
	styleUrls: ['./awareness-qr-code.component.scss']
})
export class AwarenessQrCodeComponent {
	@Input()
	height = 188;
	@Input()
	width = 188;
	linkToSvg$: Observable<string> = this.awarenessQuery.region$.pipe(map(region => this.getLink(region)));
	constructor(private awarenessQuery: AwarenessQuery, private shellQuery: ShellQuery) {}

	private getLink(region: Region): string {
		const regionName = region?.Name ?? 'EMEA';
		return `${this.shellQuery.staticFilesEndpoint}/assets/qr-codes/${regionName}.svg`;
	}
}
