import { Injectable } from '@angular/core';
import { ConfigurationItem } from '@shared/models/configuration-item';
import { PonticDesignEnum } from '@shared/models/pontic-design.enum';
import { ShellQuery } from '@shared/store/shell/shell-query';

@Injectable({
	providedIn: 'root'
})
export class PonticSelectorService {
	constructor(private shellQuery: ShellQuery) {}

	getSrcForPonticDesignImage(ponticDesign: ConfigurationItem): string {
		const ponticDesignSvgNames: Record<PonticDesignEnum, string> = {
			[PonticDesignEnum.RidgeLapPontic]: 'ridge-lap-pontic.svg',
			[PonticDesignEnum.ModifiedRidgeLapPontic]: 'modified-ridge-lap-pontic.svg',
			[PonticDesignEnum.SteinPontic]: 'stein-pontic.svg',
			[PonticDesignEnum.SanitaryPontic]: 'sanitary-pontic.svg',
			[PonticDesignEnum.OvalePontic]: 'ovale-pontic.svg'
		};

		return `${this.shellQuery.staticFilesEndpoint}/assets/pontic-designs/${ponticDesignSvgNames[ponticDesign.Id]}`;
	}

	getSrcForRadioButtonImg(): string {
		return `${this.shellQuery.staticFilesEndpoint}/assets/icons/check.svg`;
	}
}
