import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentParams, LoggingSettings } from '@shared/models/environment-params';
import { akitaDevtools, enableAkitaProdMode } from '@datorama/akita';
@Injectable({
	providedIn: 'root'
})
export class AppSettingsService {
	private appSettings: EnvironmentParams;

	constructor(private http: HttpClient) {}

	loadAppSettings(): Promise<void> {
		const baseUrl = (document.getElementsByTagName('rx-app')[0] as any).staticFilesEndpoint || '';
		return this.http
			.get(baseUrl + '/assets/config/app.settings.json')
			.toPromise()
			.then((data: EnvironmentParams) => {
				this.appSettings = data;
				this.configureAkita(data.isProduction === true);
			});
	}

	get loggingSetting(): LoggingSettings {
		if (!this.appSettings) {
			throw new Error('App Settings not loaded!');
		}

		return this.appSettings.logging;
	}

	configureAkita(isProduction: boolean) {
		if (isProduction) {
			enableAkitaProdMode();
			return;
		}
		const akitaDevToolsOptions = {
			logTrace: false,
			shallow: false,
			name: 'rx-app'
		};
		akitaDevtools(akitaDevToolsOptions);
	}
}
