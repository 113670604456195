import { ProcedureMapEnum } from '@core/procedure-helpers/models/procedure-map.enum';
import { ProcedureRule } from '../../models/procedure-unit-type-material-rules-config';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';
import { allTeeth } from '../teeth-sets';

export const rulesForRemovableImmediateDentureFramework: ProcedureRule = {
	Ids: [
		ProcedureMapEnum.DentureRemovableImmediateDenture,
		ProcedureMapEnum.DentureRemovableImmediateDentureWithoutSendTo
	],
	TeethRules: [
		{
			Ids: allTeeth,
			UnitAndMaterialRules: {
				UnitTypeRules: [
					{
						Id: UnitTypes.Clasp
					},
					{
						Id: UnitTypes.ToBeRemoved
					},
					{
						Id: UnitTypes.Regular
					}
				]
			}
		}
	]
};
