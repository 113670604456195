import { Inject, Injectable } from '@angular/core';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { ObjectUtils } from '@shared/utils/object-utils/object-utils';
import { SHARE_TOOTH_PROPS_RULES } from '@shared/rules/rules-tokens';
import { ShareToothPropsRule } from '@shared/models/share-tooth-props-rule';
import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';

@Injectable({
	providedIn: 'root'
})
export class ToothEditorSharingService {
	constructor(@Inject(SHARE_TOOTH_PROPS_RULES) private rules: ShareToothPropsRule[]) {}

	sharePropsToTeeth(teeth: Tooth[], tooth: Tooth): Tooth[] {
		return teeth?.map(t => {
			const targetTooth = t.ToothID === tooth.ToothID ? tooth : t;
			return this.mergeToothByRules(tooth, targetTooth);
		});
	}

	getToothWithSharedProps(teeth: Tooth[], tooth: Tooth): Tooth {
		return teeth?.reduce((acc, t) => this.mergeToothByRules(t, acc), {...tooth});
	}

	resetToothIfNeeded(tooth: Tooth, unitType: UnitTypes): Tooth {
		if (unitType !== UnitTypes.ImplantBased) {
			return {
				...tooth,
				ImplantBasedRestorationTypeId: null,
				AbutmentType: null,
				AbutmentMaterialId: null
			};
		}
		return tooth;
	}

	private mergeToothByRules(sourceTooth: Tooth, targetTooth: Tooth): Tooth {
		return this.rules.reduce((acc, rule) => this.copyPropsByRule(rule, sourceTooth, acc), targetTooth);
	}

	private copyPropsByRule(rule: ShareToothPropsRule, sourceTooth: Tooth, targetTooth: Tooth): Tooth {
		const { unitTypes, sharedProps } = rule;

		if (unitTypes.includes(sourceTooth.ToothInBridgeTypeID) && unitTypes.includes(targetTooth.ToothInBridgeTypeID)) {
			return this.copyProps(sharedProps, sourceTooth, targetTooth);
		}

		return targetTooth;
	}

	private copyProps(props: (keyof Tooth)[], sourceTooth: Tooth, targetTooth: Tooth): Tooth {
		return ObjectUtils.mapObject(targetTooth, ([key, value]) => (props.includes(key as keyof Tooth) ? sourceTooth[key] : value));
	}
}
